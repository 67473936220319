import React, { Component } from 'react';
import $ from "jquery";
import { Textfit } from 'react-textfit'
import posed, { PoseGroup } from 'react-pose';
import { easing } from "popmotion";
import Switch from "react-switch";
import './resources/master.min.css';
import copy from 'copy-to-clipboard';
import parse from 'html-react-parser';
import ArrowKeysReact from 'arrow-keys-react';
import io from 'socket.io-client';
import ReactTooltip from 'react-tooltip';

//import the Darwin API classes
import * as Helpers from './resources/Helpers.js';
import * as Components from './resources/Components.js';
import API from './resources/API.js';
import braintree from "braintree-web";

//import all of the images
import ImageDarwinHeaderDark from './DarwinReactLibrary/images/DarwinHeaderDark.svg';
import ImageDarwinHeaderLight from './DarwinReactLibrary/images/DarwinHeaderWhite.svg';
//import ImageDarwinGrayLogo from './DarwinReactLibrary/images/DarwinGray.svg';
import ImageDarwinWhiteLogo from './DarwinReactLibrary/images/DarwinWhite.svg';
import ImageDarwinDarkLogo from './DarwinReactLibrary/images/DarwinDark.svg';
import ImageDefaultProfile from './DarwinReactLibrary/images/DefaultProfile.svg';
import ImageDownArrowGray from './DarwinReactLibrary/images/DownArrowGray.svg';
import ImageUserProfileDark from './DarwinReactLibrary/images/UserProfileDark.svg';
import ImageUserProfileLight from './DarwinReactLibrary/images/UserProfileLight.svg';
import ImageLogout from './DarwinReactLibrary/images/LogoutDark.svg';
import ImageLogoutLight from './DarwinReactLibrary/images/LogoutLight.svg';
import ImageLightning from './DarwinReactLibrary/images/Lightning.svg';
import ImageLightningLight from './DarwinReactLibrary/images/LightningLight.svg';
import ImagePlusGreen from './DarwinReactLibrary/images/PlusGreen.svg';
import ImagePlusGreenFilled from './DarwinReactLibrary/images/PlusGreenFilled.svg';
import ImageHomeOutline from './DarwinReactLibrary/images/homeButtonOutline.svg';
import ImageHomeFilled from './DarwinReactLibrary/images/homeButtonFilled.svg';
import ImageHomeOutlineWhite from './DarwinReactLibrary/images/homeButtonOutlineWhite.svg';
import ImageHomeFilledWhite from './DarwinReactLibrary/images/homeButtonFilledWhite.svg';
import ImageExpand from './DarwinReactLibrary/images/ExpandArrow.svg';
import ImageExpand4 from './DarwinReactLibrary/images/ExpandArrow4.svg';
import ImageExpandWhite from './DarwinReactLibrary/images/ExpandArrowWhite.svg';
import ImageExpand4White from './DarwinReactLibrary/images/ExpandArrow4White.svg';
import ImageCopy from './DarwinReactLibrary/images/Copy.svg';
import ImageURL from './DarwinReactLibrary/images/UrlLink.svg';
import ImageCode from './DarwinReactLibrary/images/Code.svg';
import ImageCopyWhite from './DarwinReactLibrary/images/CopyWhite.svg';
import ImageURLWhite from './DarwinReactLibrary/images/UrlLinkWhite.svg';
import ImageCodeWhite from './DarwinReactLibrary/images/CodeWhite.svg';
import ImageXHollow from './DarwinReactLibrary/images/XButtonHollow.svg';
import ImageXFilled from './DarwinReactLibrary/images/XButtonFilled.svg';
import ImageRefresh from './DarwinReactLibrary/images/RefreshGreen.svg';
import ImageRefresh2 from './DarwinReactLibrary/images/RefreshGreen2.svg';
import ImageFixLight from './DarwinReactLibrary/images/FixWhite.svg';
import ImageRocketLight from './DarwinReactLibrary/images/RocketWhite.svg';

import SoundEnable from "./resources/sounds/enable.mp3"
import SoundNewData from "./resources/sounds/newData.mp3"

//Variable based imports go here. Regular imports must go above or an error occurs.
var prettyHtml = require('json-pretty-html').default;

//Returns the components of the url.
//For instance, /abc/def/yoyo would return ['abc', 'def', 'yoyo']
//A blank url would return ['']
function getWindowPathComponents() {
  let path = window.location.pathname
  let pathComponents = path.split("/")
  if (pathComponents[0] === "" && pathComponents.length > 1) {
    //remove the first element as it is worthless.
    pathComponents.splice(0, 1)
  }
  return pathComponents
}

//If set to false, the deep link update timer will stop.
var deepLinkUpdateTimer = true

function cancelDeepLinkTimer() {
  deepLinkUpdateTimer = false
}

//Changes the url in the browser for help with deep linking.
function updateWindowPath(newPath) {
  window.history.replaceState({}, "", newPath)
}

// The main class for the React app
export class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      view: "Account", //Account, Billing, Apps
      subview: "Home", //The subview that is showing, options are dependant on state.view
      loading: true, //shows the loading indicator in place of main body content
      error: false, //is there an error to show
      errorMessage: "", //the error message if there is one
      confirmation: false, //are we showing a confirmation
      confirmationProps: {}, //the props for the confirmation
      userInfo: {}, //the user's information
      sound: window.location.origin + SoundEnable, //the sound to play: must add the origin here
                                                   //but nowhere else because Safari doesn't play local paths.
      playedSoundFromClick: false, //have we played a sound from a click yet? If not, then we can't play because of Safari.
      deepLinkLoading: false, //if true, shroud the screen as the deep link loads.
                              //set this where we are parsing the pathComponents below.
      darkMode: false, //is the UI dark or light. This is set in componentDidMount

      //billing information
      billingOrganizations: false, //the organizations we are managing billing for
      selectedBillingOrganization: false, //the selected billing organization and associated data
      billingClient: false, //the billing client
      billingClientToken: false, //the client token for use with Braintree
      billingCreditCards: false, //the credit cards on file for this organization
      billingCreditCardSelected: false, //the selected credit card to edit
      billingCreditCardIsDefault: false, //should the selected credit card be the default
      billingPaymentLoading: false, //the payment section is loading
      billingInvoices: false, //the invoices on file for this organization
      billingInvoicesLoading: false, //the invoices section is loading
      billingPaymentFormLoading: false, //is the billing form loading
      automatedBilling: true, //is automated billing turned on
      backgroundColor: false, //the current background color, false is the default
      backgroundColors: [], //the list of background color set objects

      //apps information
      apps: false, //the list of apps (Oz, Nature, ...) that this user has access to.
    }

    let pathComponents = getWindowPathComponents()
    console.log(pathComponents)
    switch (pathComponents[0]) {
      case "Billing":
        this.state.view = "Billing"
        break;
      case "Apps":
        this.state.view = "Apps"
        if (pathComponents.length >= 2) {
          this.state.deepLinkLoading = true
        }
        break;
      case "Account":
        this.state.view = "Account"
        break;
      default:
        break;
    }

    this.switchTabs = this.switchTabs.bind(this);
    this.loadTab = this.loadTab.bind(this);
    this.loadUserInfo = this.loadUserInfo.bind(this);
    this.showError = this.showError.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.confirmationAction = this.confirmationAction.bind(this);
    this.selectBillingOrganization = this.selectBillingOrganization.bind(this);
    this.createPaymentMethod = this.createPaymentMethod.bind(this);
    this.cancelPaymentMethod = this.cancelPaymentMethod.bind(this);
    this.editPaymentMethod = this.editPaymentMethod.bind(this);
    this.editCardDefaultChanged = this.editCardDefaultChanged.bind(this);
    this.saveCreditCard = this.saveCreditCard.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.automatedBillingChanged = this.automatedBillingChanged.bind(this);
    this.showToast = this.showToast.bind(this)
    this.playSound = this.playSound.bind(this)
    this.setBackgroundColor = this.setBackgroundColor.bind(this)
    this.changeDarkMode = this.changeDarkMode.bind(this)

    this.soundPlayer = React.createRef()

    //1) Check to see if we are logged in and if not redirect to the login screen
    API.checkAuthentication(() => {
      //2) Load the current user's information
      this.loadUserInfo()
      //3) Load the current tab
      this.loadTab()
      //4) Call this to test out a method at the load of the app. Used for debugging.
      /*API.callDarwinAPI("GET", "infoAPI", {}, (result) => {
        console.log("infoAPI", result)
      })*/
    })
  }

  componentDidMount() {
    if (this.state.deepLinkLoading) {
      this.dlTime = setInterval(() => {
        if (deepLinkUpdateTimer === false) {
          clearInterval(this.dlTime)
          this.setState({
            deepLinkLoading: false
          })
        }
      }, 100)
    }
    //Change this to false so that the default is white.
    if (Helpers.isDevelopment()) {
      this.changeDarkMode(false)
    } else {
      this.changeDarkMode(true)
    }
  }

  /*
  * Logs the user out of the program.
  */
  logout() {
    API.logout()
  }

  /*
  * Switches to the tab provided
  * Account, Billing, ...
  */
  switchTabs(newTab) {
    this.dismissError()
    this.setState({
      view: newTab,
      subview: "Home",
      loading: true,
      billingOrganizations: false,
      selectedBillingOrganization: false,
      apps: false
    }, () => {
      updateWindowPath("/" + this.state.view)
      this.loadTab()
    })
  }

  /*
  * Loads the tab in question
  */
  loadTab() {

    switch (this.state.view) {
      case "Billing":
        //1) Get a list of all the organizations this user has access to
        API.callDarwinAPI("GET", "billingOrganizations", {}, (result) => {
          if ("error" in result) {
            this.showError(result.error)
            return
          }
          this.setState({
            loading: false,
            billingOrganizations: result.data
          }, () => {
            if (result.data.length === 1) {
              //auto-select this organization as it is the only one we have access to
              this.selectBillingOrganization(result.data[0])
            }
          })
        })
        break;
      case "Apps":
        //1) Get a list of all the apps this user has access to
        API.callDarwinAPI("GET", "accountApps", {}, (result) => {
          if ("error" in result) {
            cancelDeepLinkTimer()
            this.showError(result.error)
            return
          }
          this.setState({
            loading: false,
            apps: result.data
          })
        })
        break;
      default:
        console.log("TODO: Implement Tab - " + this.state.view)
        setTimeout(() => {
          this.setState({
            loading: false
          })
        }, 1500)
        break;
    }
  }

  /*
  * Loads the user information
  */
  loadUserInfo() {
    API.callDarwinAPI("GET", "userProfile", {}, (result) => {
      if ("error" in result) {
        this.showError(result.error)
        return
      }
      console.log("User Profile", result.data)
      this.setState({
        userInfo: result.data
      })
    })
  }

  /*
  * Show the error with a dismiss button
  */
  showError(message) {
    console.error("ERROR", message)
    this.setState({
      loading: false,
      error: true,
      errorMessage: message
    })
  }

  /*
  * Dismiss the error message.
  */
  dismissError() {
    this.setState({
      error: false,
      errorMessage: ""
    })
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(message, accept, deny, action) {
    let dismissFunc = () => {
      this.setState({
        confirmation: false,
        confirmationProps: {}
      })
    }
    this.setState({
      confirmation: true,
      confirmationProps: {
        dismiss: dismissFunc,
        message: message,
        accept: accept,
        deny: deny,
        action: action
      }
    })
  }

  /*
  * An action was performed on the confirmation
  */
  confirmationAction(accepted) {
    if (this.state.confirmationProps.callback !== undefined) {
      this.state.confirmationProps.callback(accepted)
    }
  }

  /*
  * Select a Billing Organization and load the corresponding payment methods and invoices
  */
  selectBillingOrganization(org) {
    //1) Set the state as selected
    this.setState({
      selectedBillingOrganization: org,
      billingClientToken: false,
      billingCreditCards: false,
      billingInvoices: false,
      billingPaymentLoading: true,
      billingInvoicesLoading: true
    }, () => {
      //2) Retreive a clientToken with the organization's ID.
      let currentOrgID = this.state.selectedBillingOrganization.organizationID
      API.callDarwinAPI("GET", "billingClientToken/" + currentOrgID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            billingPaymentLoading: false
          })
          this.showError(result.error)
          return
        }
        //make sure we haven't changed the client in between calls.
        if (this.state.selectedBillingOrganization.organizationID !== currentOrgID) {
          //the org has changed so dismiss this request
          return
        }
        //3) create the braintree client
        braintree.client.create({
          authorization: result.data.token
        }).then(clientInstance => {
          //3.1) store the braintree client for future use
          this.setState({
            billingClient: clientInstance,
            billingClientToken: result.data.token
          })
          if (result.data.account === false) {
            //3.2) There are no payment methods available
            this.setState({
              billingCreditCards: [],
              billingPaymentLoading: false
            })
            return
          }
          //3.3) Create the Vault Manager
          return braintree.vaultManager.create({
            client: clientInstance
          })
        }).then(vaultManager => {
          //3.4) Fetch the payment methods on file
          return vaultManager.fetchPaymentMethods({
            defaultFirst: true
          })
        }).then(paymentMethods => {
          console.log("Payment Methods:", paymentMethods)
          let newMethods = []
          paymentMethods.forEach((pm) => {
            if (pm.type === "CreditCard") {
              newMethods.push(pm)
            }
          })
          this.setState({
            billingCreditCards: newMethods,
            billingPaymentLoading: false
          })
        }).catch(err => {
          this.setState({
            billingPaymentLoading: false,
            billingCreditCards: []
          })
          console.log(err)
          this.showError(err.message)
          return;
        });
      })
      //4) Get a list of all of this organization's invoices.
      console.log("Get a list of all of this organization's invoices.")
      API.callDarwinAPI("GET", "billingInvoices/" + currentOrgID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            billingInvoicesLoading: false
          })
          this.showError(result.error)
          return
        }
        //make sure we haven't changed the client in between calls.
        if (this.state.selectedBillingOrganization.organizationID !== currentOrgID) {
          //the org has changed so dismiss this request
          return
        }
        this.setState({
          billingInvoices: result.data.invoices,
          automatedBilling: (result.data.automatedBilling === "1" ? true : false),
          billingInvoicesLoading: false
        })
      })
    })
  }

  /*
  * Creates a new payment method
  */
  createPaymentMethod() {
    this.setState({
      subview: "CreatePaymentMethod",
      billingPaymentFormLoading: false
    })
  }

  /*
  * Cancels the creation or editing of a payment method
  */
  cancelPaymentMethod() {
    this.setState({
      subview: "Home"
    }, () => {
      this.selectBillingOrganization(this.state.selectedBillingOrganization)
    })
  }

  /*
  * Shows the screen to edit a payment method
  */
  editPaymentMethod(method) {
    console.log("method: ", method)
    this.setState({
      subview: "EditPaymentMethod",
      billingPaymentFormLoading: false,
      billingCreditCardSelected: method,
      billingCreditCardIsDefault: method.default
    })
  }

  /*
  * Saves the changes to the credit card if there are any or does nothing.
  */
  saveCreditCard() {
    if (this.state.billingCreditCardSelected.default === this.state.billingCreditCardIsDefault) {
      //nothing to update here so just return
      return
    }
    this.setState({
      billingPaymentFormLoading: true
    }, () => {
      //Call the API to update the default status of this card.
      API.callDarwinAPI("POST", "billingCreditCard/" + this.state.selectedBillingOrganization.organizationID, {
        "token": this.state.billingCreditCardSelected.nonce,
        "default": (this.state.billingCreditCardIsDefault ? 1 : 0),
        "editing": 1
      }, (result) => {
        this.setState({
          billingPaymentFormLoading: false
        })
        if ("error" in result) {
          this.showError(result.error)
          return
        }
        //Go back to the home page as we have saved this method.
        this.cancelPaymentMethod()
      })
    })
  }

  /*
  * Deletes the selected credit card.
  */
  deleteCreditCard() {
    this.showConfirmation("Are you sure you want to remove this credit card.", "Remove", "Cancel", (acceptance) => {
      if (!acceptance) {
        return
      }
      this.setState({
        billingPaymentFormLoading: true
      }, () => {
        //Call the API to update the default status of this card.
        API.callDarwinAPI("POST", "billingCreditCard/" + this.state.selectedBillingOrganization.organizationID, {
          "token": this.state.billingCreditCardSelected.nonce,
          "default": 0,
          "editing": 2
        }, (result) => {
          this.setState({
            billingPaymentFormLoading: false
          })
          if ("error" in result) {
            this.showError(result.error)
            return
          }
          //Go back to the home page as we have saved this method.
          this.cancelPaymentMethod()
        })
      })
    })
  }

  /*
  * Is default payment method changed for the selected credit card
  */
  editCardDefaultChanged(isOn) {
    this.setState({
      billingCreditCardIsDefault: isOn
    })
  }

  /*
  * Automated billing was toggled
  */
  automatedBillingChanged(isOn) {
    this.setState({
      automatedBilling: isOn
    })
    //Call the API to change automated billing settings.
    API.callDarwinAPI("POST", "billingAutomated/" + this.state.selectedBillingOrganization.organizationID, {
      "automated": (isOn ? 1 : 0)
    }, (result) => {
      if ("error" in result) {
        /*this.setState({
          billingInvoicesLoading: false
        })*/
        this.showError(result.error)
        return
      }
      console.log("billingAutomated", result)
    })
  }

  /*
  * Shows a toast at the bottom of the screen for a second or so
  */
  showToast(message) {
    this.setState({
      toast: message
    }, () => {
      if (this.refs.appToast !== null) {
        this.refs.appToast.show()
      }
    })
  }

  setBackgroundColor(color, priority, add) {

    let bcs = this.state.backgroundColors
    let newColor = {
      color: color,
      priority: priority
    }

    if (add === true) {
      bcs.push(newColor)
    } else {
      //find the existing component and remove it
      for (let i = 0; i < bcs.length; i = i + 1) {
        if (bcs[i].color === color && bcs[i].priority === priority) {
          bcs.splice(i, 1)
          break;
        }
      }
    }
    //now find the background color with the highest priority
    let newBC = false
    for (let i = 0; i < bcs.length; i = i + 1) {
      if (newBC === false) {
        //there is no current pick
        newBC = bcs[i]
      } else if (bcs[i].priority >= newBC) {
        //the priority was higher so this is our new pick
        newBC = bcs[i]
      }
    }

    this.setState({
      backgroundColor: newBC.color,
      backgroundColors: bcs
    })
  }

  /*
  * Plays the provided sound. If fromClick, then we don't play the sound
  */
  playSound(sound, fromClick = false) {
    if (!fromClick && !this.state.playedSoundFromClick) {
      console.log("Can't play sounds until we click to play at least once.")
      return
    }
    if (fromClick) {
      if (this.soundPlayer.current !== null) {
        console.log("sound:", sound)
        document.getElementById("mainSound").src = window.location.origin + sound
        this.soundPlayer.current.playSound()
      }
    }
    this.setState({
      sound: window.location.origin + sound,
      playedSoundFromClick: true
    }, () => {
      if (!fromClick && this.soundPlayer.current !== null) {
        this.soundPlayer.current.playSound()
      }
    })
  }

  /*
  * Switches between dark mode and light mode for the UI.
  */
  changeDarkMode(dark) {
    this.setState({
      darkMode: dark
    })
    if (dark) {
      document.body.style.backgroundColor = "#10151F";
    } else {
      document.body.style.backgroundColor = "white";
    }
  }

  render() {

    let headerProps = {
      view: this.state.view,
      switchTabs: this.switchTabs,
      logout: this.logout,
      userInfo: this.state.userInfo,
      darkMode: this.state.darkMode,
      changeDarkMode: this.changeDarkMode
    }
    let errorProps = {
      title: "Error",
      message: this.state.errorMessage,
      dismiss: this.dismissError,
      darkMode: this.state.darkMode
    }
    let braintreeProps = {
      client: this.state.billingClient,
      clientToken: this.state.billingClientToken,
      cancel: true,
      cancelAction: this.cancelPaymentMethod,
      submitText: "Create Payment Method",
      showError: this.showError,
      organization: this.state.selectedBillingOrganization
    }
    let footerProps = {
      view: this.state.view,
      darkMode: this.state.darkMode
    }
    let appAppProps = {
      apps: this.state.apps,
      showError: this.showError,
      showToast: this.showToast,
      playSound: this.playSound,
      soundEnabled: this.state.playedSoundFromClick,
      setBackgroundColor: this.setBackgroundColor,
      darkMode: this.state.darkMode
    }
    let loadingScreenProps = {
      hide: !this.state.deepLinkLoading,
      darkMode: this.state.darkMode
    }
    let soundPlayerProps = {
      sound: this.state.sound,
    }

    let mainBodyStyle = {}
    if (this.state.backgroundColor !== false) {
      mainBodyStyle.backgroundColor = this.state.backgroundColor
    }

    let actionPopupProps = {
      darkMode: this.state.darkMode
    }

    return (
      <div className={"App " + (this.state.darkMode ? "AppDark" : "")}>
        <LoadingScreen {...loadingScreenProps} />
        <SoundPlayer soundID="mainSound" {...soundPlayerProps} ref={this.soundPlayer}/>
        <Header {...headerProps} />
        <div className="MainBody" style={mainBodyStyle}>
          { this.state.confirmation &&
            <Confirmation {...this.state.confirmationProps} />
          }
          { this.state.error &&
            <Popup {...errorProps} />
          }
          { !this.state.error && this.state.loading &&
            <Components.LoadingIndicator style={{paddingTop:"100px"}} />
          }
          { !this.state.error && !this.state.loading &&
            <span>
              { this.state.view === "Billing" &&
                <span className="BillingTab">
                  <div className="TabTitle">{this.state.view}</div>
                  { this.state.subview === "Home" &&
                    <span>
                      <div className="TabSubtitle" style={{paddingTop:"30px"}}>Organizations</div>
                      { this.state.billingOrganizations !== false && this.state.billingOrganizations.length === 0 &&
                        <div className="TabEmptyDescription">
                          You don't have access to the billing of any organizations.<br/>
                          Contact your organization's administrator to gain access.
                        </div>
                      }
                      { this.state.billingOrganizations.length > 0 &&
                        <span>
                          <div className="orgContainer">
                            <PoseGroup animateOnMount preEnterPose="original">
                              { this.state.billingOrganizations.map((org, iter) => (
                                <SlideUpDiv key={"orgDiv" + iter} iter={iter} className={"orgDiv " + (this.state.selectedBillingOrganization.organizationID === org.organizationID ? "orgDivSelected" : "")} onClick={() => this.selectBillingOrganization(org)}>
                                  <div className="orgDivLogo">
                                    <img src={org.logo} alt="" />
                                  </div>
                                  <Textfit className="orgDivName" mode="single" min={4} max={16}>
                                    {org.name}
                                  </Textfit>
                                </SlideUpDiv>
                              ))}
                            </PoseGroup>
                          </div>
                          { this.state.selectedBillingOrganization !== false &&
                            <span>
                              <div className="TabSubtitle">
                                Payment Methods
                                { this.state.billingClientToken !== false &&
                                  <PlusButton onClick={this.createPaymentMethod} />
                                }
                              </div>
                              <div className="TabSubdescription">Organization - {this.state.selectedBillingOrganization.name}</div>
                              { this.state.billingPaymentLoading &&
                                <Components.LoadingIndicator />
                              }
                              { this.state.billingCreditCards !== false && this.state.billingCreditCards.length === 0 &&
                                <div className="TabEmptyDescription">
                                  Click the plus button above to add your credit/debit card.<br/>
                                  Then, you'll be able to pay your invoices, and activate auto-pay.
                                </div>
                              }
                              { this.state.billingCreditCards.length > 0 &&
                                <span>
                                  <div className="iOSSwitchBlock" style={{marginTop:"0px", marginBottom:"24px"}}>
                                    <div className="iOSSwitchBlockGroup">
                                      <IOSSwitch handleChange={this.automatedBillingChanged} checked={this.state.automatedBilling} />
                                      <div className="details">
                                        Automated Billing. Your card will automatically be charged at the beginning of every month.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="creditCardContainer">
                                    <PoseGroup animateOnMount preEnterPose="original">
                                      { this.state.billingCreditCards.map((card, iter) => (
                                        <SlideUpDiv className={"creditCard " + (card.default ? "" : "creditCardWhite")} key={"card" + iter} iter={iter} onClick={() => {this.editPaymentMethod(card)}}>
                                          <div className="creditCardTop">
                                            <img src={(card.default ? ImageDarwinWhiteLogo : ImageDarwinDarkLogo)} alt="" />
                                            <div className="creditCardTitleGroup">
                                              <div className="creditCardTitle">CARD</div>
                                              { card.default &&
                                                <div className="creditCardPrimary">PRIMARY</div>
                                              }
                                            </div>
                                          </div>
                                          <div className="creditCardNumberGroup">
                                            <div className="creditCardNumber">****</div>
                                            <div className="creditCardNumber">****</div>
                                            <div className="creditCardNumber">****</div>
                                            <div className="creditCardNumber">{card.details.lastFour}</div>
                                          </div>
                                          <div className="creditCardType">
                                            {card.details.cardType}
                                          </div>
                                        </SlideUpDiv>
                                      ))}
                                    </PoseGroup>
                                  </div>
                                </span>
                              }
                              <div className="TabSubtitle">Invoices</div>
                              <div className="TabSubdescription">Organization - {this.state.selectedBillingOrganization.name}</div>
                              { this.state.billingInvoicesLoading &&
                                <Components.LoadingIndicator />
                              }
                              { this.state.billingInvoices !== false && this.state.billingInvoices.length === 0 &&
                                <div className="TabEmptyDescription">
                                  There are no invoices for this organization.<br/>
                                  There is nothing due at this time.
                                </div>
                              }
                              { this.state.billingInvoices.length > 0 &&
                                <div className="invoicesContainer">
                                  TODO: Show the Invoices.
                                </div>
                              }
                            </span>
                          }
                        </span>
                      }
                    </span>
                  }
                  { this.state.subview === "CreatePaymentMethod" &&
                    <span>
                      <div className="TabSubtitle" style={{paddingTop:"30px"}}>New Payment Method</div>
                      <div className="TabSubdescription">Organization - {this.state.selectedBillingOrganization.name}</div>
                      { this.state.billingPaymentFormLoading &&
                        <Components.LoadingIndicator />
                      }
                      { !this.state.billingPaymentFormLoading &&
                        <BraintreeCardForm {...braintreeProps} />
                      }
                    </span>
                  }
                  { this.state.subview === "EditPaymentMethod" &&
                    <span>
                      <div className="TabSubtitle" style={{paddingTop:"30px"}}>Edit Payment Method</div>
                      <div className="TabSubdescription">Organization - {this.state.selectedBillingOrganization.name}</div>
                      { this.state.billingPaymentFormLoading &&
                        <Components.LoadingIndicator />
                      }
                      { !this.state.billingPaymentFormLoading &&
                        <span>
                          <div className="creditCardContainer">
                            <PoseGroup animateOnMount preEnterPose="original">
                              <SlideUpDiv className={"creditCard " + (this.state.billingCreditCardSelected.default ? "" : "creditCardWhite")} key="card-edit-1" iter={0}>
                                <div className="creditCardTop">
                                  <img src={(this.state.billingCreditCardSelected.default ? ImageDarwinWhiteLogo : ImageDarwinDarkLogo)} alt="" />
                                  <div className="creditCardTitleGroup">
                                    <div className="creditCardTitle">CARD</div>
                                    { this.state.billingCreditCardSelected.default &&
                                      <div className="creditCardPrimary">PRIMARY</div>
                                    }
                                  </div>
                                </div>
                                <div className="creditCardNumberGroup">
                                  <div className="creditCardNumber">****</div>
                                  <div className="creditCardNumber">****</div>
                                  <div className="creditCardNumber">****</div>
                                  <div className="creditCardNumber">{this.state.billingCreditCardSelected.details.lastFour}</div>
                                </div>
                                <div className="creditCardType">
                                  {this.state.billingCreditCardSelected.details.cardType}
                                </div>
                              </SlideUpDiv>
                            </PoseGroup>
                          </div>
                          { !this.state.billingCreditCardSelected.default &&
                            <div className="iOSSwitchBlock">
                              <div className="iOSSwitchBlockGroup">
                                <IOSSwitch handleChange={this.editCardDefaultChanged} checked={this.state.billingCreditCardSelected.default} />
                                <div className="details">
                                  Default Card. This card will be used for automated billing.
                                </div>
                              </div>
                            </div>
                          }
                          <div className="buttonsDiv">
                            <MaterialButton onClick={this.cancelPaymentMethod}>Cancel</MaterialButton>
                            { !this.state.billingCreditCardSelected.default &&
                              <MaterialButton onClick={this.saveCreditCard} color={this.state.billingCreditCardSelected.default !== this.state.billingCreditCardIsDefault ? "Green" : "Gray"}>Save Card</MaterialButton>
                            }
                            <MaterialButton onClick={this.deleteCreditCard} color="Red">Remove</MaterialButton>
                          </div>
                        </span>
                      }
                    </span>
                  }
                </span>
              }
              { this.state.view === "Account" &&
                <span className="AccountTab">
                  <div className="TabTitle">{this.state.view}</div>
                  <div className="TabSubtitle" style={{paddingTop:"30px"}}>TODO</div>
                </span>
              }
              { this.state.view === "Apps" &&
                <AppApps {...appAppProps} />
              }
            </span>
          }
          <div className="toastContainer">
            <ActionPopup ref="appToast" {...actionPopupProps}>{this.state.toast}</ActionPopup>
          </div>
        </div>
        <Footer {...footerProps} />
      </div>
    );
  }
}

export class AppApps extends Component {

  constructor(props) {
    super(props)
    console.log("AppApps Loaded")
    this.state = {
      subview: "Home",
      selectedApp: false,
      apis: false,
      apisLoading: false,
      logs: false,
      logPage: 0,
      logPageTotal: 0,
      logsLoading: false,
      logFullScreen: false,
      logExpanded: false,
      logExpandedIndex: 0,
      lastScrollPosition: 0,
      logFilters: { //the filters that are applied to the log search.
        messages: true,
        errors: true
      },
      notificationSoundEnabled: props.soundEnabled,
      userDetails: false,
      formShowing: false, //will be the name of a form if showing, and false otherwise.
      stackTraceLinesExpanded: [], //the stack trace lines that are to be expanded.
      detailsTab: "stackTrace", //the tab to show - (stackTrace, request)
      statusNotes: [], //the list of status notes to display
      statusNotesLoading: false, //are we loading the status notes
      documentation: false,
      documentationLoading: false,
    }

    this.selectApp = this.selectApp.bind(this)
    this.handleSoundSwitch = this.handleSoundSwitch.bind(this)
    this.selectOzAPI = this.selectOzAPI.bind(this)
    this.selectOzLog = this.selectOzLog.bind(this)
    this.loadOzAPIs = this.loadOzAPIs.bind(this)
    this.loadOzLogs = this.loadOzLogs.bind(this)
    this.alertNewLog = this.alertNewLog.bind(this)
    this.parseLog = this.parseLog.bind(this)
    this.parseStackTrace = this.parseStackTrace.bind(this)
    this.expandStackTraceLine = this.expandStackTraceLine.bind(this)
    this.changeDetailsTab = this.changeDetailsTab.bind(this)
    this.ozGetNewData = this.ozGetNewData.bind(this)
    this.refreshLogging = this.refreshLogging.bind(this)
    this.expandLogging = this.expandLogging.bind(this)
    this.closeLoggingFullScreen = this.closeLoggingFullScreen.bind(this)
    this.expandSiblingLog = this.expandSiblingLog.bind(this)
    this.closeLoggingWithLog = this.closeLoggingWithLog.bind(this)
    this.ozLogCopy = this.ozLogCopy.bind(this)
    this.ozLogLink = this.ozLogLink.bind(this)
    this.ozLogCode = this.ozLogCode.bind(this)
    this.ozLogPrettyPrint = this.ozLogPrettyPrint.bind(this)
    this.ozChangeFilterMessages = this.ozChangeFilterMessages.bind(this)
    this.ozChangeFilterErrors = this.ozChangeFilterErrors.bind(this)
    this.ozChangeFilter = this.ozChangeFilter.bind(this)
    this.ozShowUser = this.ozShowUser.bind(this)
    this.closeUserDetails = this.closeUserDetails.bind(this)
    this.showForm = this.showForm.bind(this)
    this.printObject = this.printObject.bind(this)
    this.loadStatusNotes = this.loadStatusNotes.bind(this)
    this.clearNote = this.clearNote.bind(this)

    this.loadDocumentationAPIs = this.loadDocumentationAPIs.bind(this)
    this.selectDocumentationAPI = this.selectDocumentationAPI.bind(this)
    this.loadDocumentation = this.loadDocumentation.bind(this)
    this.scrollToDoc = this.scrollToDoc.bind(this)
  }

  componentDidMount() {
    //see if we need to select an app based on the url
    let paths = getWindowPathComponents()
    let found = false
    if (paths.length >= 2) {
      let search = paths[1]
      for (let i = 0; i < this.props.apps.length; i = i + 1) {
        if (this.props.apps[i].name === search) {
          //we need to select this api.
          found = this.props.apps[i]
          this.selectApp(this.props.apps[i], false)
          break;
        }
      }
    }
    if (!found) {
      cancelDeepLinkTimer()
    }
  }

  selectApp(app, updateURL = true) {

    if (updateURL) {
      updateWindowPath("/Apps/" + app.name)
    }

    switch (app.name) {
      case "Oz":
        this.setState({
          selectedApp: app,
          subview: "Oz-Home"
        }, () => {
          this.loadOzAPIs()
        })
        break;
      case "Documentation":
        this.setState({
          selectedApp: app,
          subview: "Documentation-Home"
        }, () => {
          this.loadDocumentationAPIs()
        })
        break;
      case "Nature":
        cancelDeepLinkTimer()
        this.setState({
          selectedApp: app,
          subview: "Nature-Home"
        })
        break;
      case "Status":
        cancelDeepLinkTimer()
        this.setState({
          selectedApp: app,
          subview: "Status-Home"
        }, () => {
          this.loadStatusNotes()
        })
        break;
      default:
        cancelDeepLinkTimer()
        this.setState({
          selectedApp: app,
          subview: "TODO"
        })
        break;
    }
  }

  loadStatusNotes() {
    this.setState({
      statusNotesLoading: true
    }, () => {
      API.callDarwinAPI("GET", "statusNotes", {}, (result) => {
        if ("error" in result) {
          this.setState({
            statusNotesLoading: false
          })
          this.props.showError(result.error)
          return
        }
        console.log(result)
        this.setState({
          statusNotesLoading: false,
          statusNotes: result.data.notes,
        })
      })
    })
  }

  clearNote(noteID) {
    this.setState({
      statusNotesLoading: true
    }, () => {
      API.callDarwinAPI("DELETE", "statusNote/" + noteID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            statusNotesLoading: false
          })
          this.props.showError(result.error)
          return
        }
        //reload the status notes
        this.loadStatusNotes()
      })
    })
  }

  handleSoundSwitch(isOn) {
    if (isOn) {
      //enable the sound switch
      this.props.playSound(SoundEnable, true)
    }
    this.setState({
      notificationSoundEnabled: isOn
    })
  }

  ozHome() {
    updateWindowPath("/Apps/Oz")
    this.setState({
      subview: "Oz-Home"
    }, () => {
      this.loadOzAPIs()
    })
  }

  //Loads the APIs for Oz
  loadOzAPIs() {
    this.setState({
      apisLoading: true,
      apis: false
    }, () => {
      //1) Load the apis to select from
      API.callDarwinAPI("GET", "ozAPIs", {}, (result) => {
        if ("error" in result) {
          cancelDeepLinkTimer()
          this.setState({
            apis: false
          })
          console.log("error!")
          this.props.showError(result.error)
          return
        }
        console.log("ozAPIs", result.data)
        let newState = {
          apis: result.data,
          apisLoading: false
        }
        //2) Determine if we should select an API from the deep link
        let paths = getWindowPathComponents()
        let found = false
        if (paths.length >= 3) {
          let search = paths[2]
          for (let i = 0; i < newState.apis.length; i = i + 1) {
            if (newState.apis[i].id === search) {
              //we need to select this api.
              found = newState.apis[i]
            }
          }
        }
        this.setState(newState, () => {
          if (found !== false) {
            this.selectOzAPI(found, false)
            if (paths.length >= 4 && paths[3] === "Logs") {
              this.expandLogging(false)
            }
          } else {
            cancelDeepLinkTimer()
          }
        })
      })
    })
  }

  selectOzAPI(api, updateURL = true) {

    if (updateURL) {
      updateWindowPath("/Apps/Oz/" + api.id)
    }

    this.setState({
      selectedAPI: api,
      subview: "Oz-Selected",
      logFilters: {
        messages: true,
        errors: true
      }
    }, () => {
      this.loadOzLogs(0, !updateURL)
    })
  }

  //Loads the logs for the selected API
  loadOzLogs(page = 0, deepLink = false) {
    console.log("loading page:", page)
    this.setState({
      logsLoading: true,
      logs: false,
      logPage: page
    }, () => {
      let requestData = {
        limit: 30,
        page: page,
        messages: this.state.logFilters.messages ? 1 : 0,
        errors: this.state.logFilters.errors ? 1 : 0
      }
      let logType = false
      let logID = false
      if (deepLink) {
        let windowPaths = getWindowPathComponents()
        if (windowPaths.length >= 5) {
          //get the log id, first character must be M or E, next must be a number
          let lm = windowPaths[4]
          if (lm.length >= 2) {
            if (lm.charAt(0) === "M") {
              logType = "Message"
              logID = lm.substring(1)
            } else if (lm.charAt(0) === "E") {
              logType = "Error"
              logID = lm.substring(1)
            }
          }
        }
        if (logType !== false && logID !== false) {
          requestData.type = logType
          requestData.logID = logID
        } else {
          cancelDeepLinkTimer()
        }
      }
      API.callDarwinAPI("GET", "ozLogs/" + this.state.selectedAPI.id, requestData, (result) => {
        if ("error" in result) {
          cancelDeepLinkTimer()
          this.setState({
            logs: false
          })
          console.log("error!")
          this.props.showError(result.error)
          return
        }
        //now parse each log.
        let numberOfPages = result.data.pages
        let logs = result.data.logs
        for (let i = 0; i < logs.length; i = i + 1) {
          logs[i] = this.parseLog(logs[i])
        }

        this.setState({
          logs: logs,
          logPageTotal: numberOfPages,
          logsLoading: false
        }, () => {
          if (deepLink) {
            //see if we need to view a log in detail.
            if (logType !== false && logID !== false) {
              //we need to view a log in detail, first try to find it.
              let foundLog = false
              for (let i = 0; i < logs.length; i = i + 1) {
                if (logs[i].id === logID && logs[i].type === logType) {
                  foundLog = logs[i]
                  break;
                }
              }
              if (foundLog !== false) {
                this.expandLoggingWithLog(null, foundLog)
              }
            }
            cancelDeepLinkTimer()
          }
        })
      })
    })
  }

  documentationHome() {
    updateWindowPath("/Apps/Documentation")
    this.setState({
      subview: "Documentation-Home"
    }, () => {
      this.loadDocumentationAPIs()
    })
  }

  //Loads the APIs for Documentation
  loadDocumentationAPIs() {
    this.setState({
      apisLoading: true,
      apis: false
    }, () => {
      //1) Load the apis to select from
      API.callDarwinAPI("GET", "ozAPIs", {}, (result) => {
        if ("error" in result) {
          cancelDeepLinkTimer()
          this.setState({
            apis: false
          })
          console.log("error!")
          this.props.showError(result.error)
          return
        }
        console.log("documentationAPIs", result.data)
        let newState = {
          apis: result.data,
          apisLoading: false
        }
        //2) Determine if we should select an API from the deep link
        let paths = getWindowPathComponents()
        let found = false
        if (paths.length >= 3) {
          let search = paths[2]
          for (let i = 0; i < newState.apis.length; i = i + 1) {
            if (newState.apis[i].id === search) {
              //we need to select this api.
              found = newState.apis[i]
            }
          }
        }
        this.setState(newState, () => {
          if (found !== false) {
            this.selectDocumentationAPI(found, false)
          } else {
            cancelDeepLinkTimer()
          }
        })
      })
    })
  }
  
  selectDocumentationAPI(api, updateURL = true) {
    if (updateURL) {
      updateWindowPath("/Apps/Documentation/" + api.id)
    }

    this.setState({
      selectedAPI: api,
      subview: "Documentation-Selected",
    }, () => {
      this.loadDocumentation("latest", false)
    })
  }

  loadDocumentation(version = "latest", deepLink = false) {
    console.log("loading documentation")
    this.setState({
      documentationLoading: true,
      documentation: false
    }, () => {
      let requestData = {
        "version": version
      }
      API.callDarwinAPI("GET", "documentation/" + this.state.selectedAPI.id, requestData, (result) => {
        if ("error" in result) {
          cancelDeepLinkTimer()
          this.setState({
            documentationLoading: false,
            documentation: false,
          })
          console.log("error!", result)
          cancelDeepLinkTimer()
          this.props.showError(result.error)
          return
        }
        //now get the documentation
        console.log("docs", result)
        let d3 = false;
        if (result.data.documentation !== false) {
          //parse into a JSON object
          d3 = JSON.parse(result.data.documentation)
          //now parse the inputs for each method
          for (let i = 0; i < d3.methods.length; i = i + 1) {
            let obj = d3.methods[i].inputs;
            let inputs = [];
            for (let [key, value] of Object.entries(obj)) {
              value["name"] = key
              inputs.push(value)
            }
            console.log(inputs)
            d3.methods[i].parameters = inputs
          }
        }
        this.setState({
          documentationLoading: false,
          documentation: d3
        }, () => {
          cancelDeepLinkTimer()
        })
      })
    })
  }

  //Alerts the user that a new log has occurred.
  alertNewLog() {
    this.props.showToast("New Log")
    if (this.state.notificationSoundEnabled) {
      this.props.playSound(SoundNewData)
    }
  }

  //Loads the new data for when a new Log has occured and adds it to the current set of results.
  ozGetNewData(data) {
    let newLog = JSON.parse(data)
    console.log("oz get new data", newLog)
    if (this.state.logsLoading) {
      //the logs are already loading so just return.
      return
    }
    //if we are on the first page of data, then load the first page of data.
    let append = true
    if (this.state.logPage !== 0) {
      append = false
    }
    if (newLog.type === "Messages" && this.state.logFilters.messages === 0) {
      append = false
    }
    if (newLog.type === "Error" && this.state.logFilters.errors === 0) {
      append = false
    }
    if (!append) {
      this.alertNewLog()
      return
    }

    //add the log to the front of the results
    let logs = this.state.logs
    //parse and format the new log
    newLog = this.parseLog(newLog)
    newLog.expanded = true
    newLog = this.ozLogPrettyPrint(newLog)
    if (newLog.contentBodyPretty === undefined) {
      newLog.prettyPrint = false
    } else {
      newLog.prettyPrint = true
    }
    logs.unshift(newLog)

    //see if we should collapse the logs that are open.
    for (let i = 1; i < logs.length; i = i + 1) {
      if (logs[i].expanded) {
        logs[i].expanded = false
      } else {
        break;
      }
    }

    let newState = {
      logs: logs
    }

    //if we are viewing full screen we need to update the indexes and what not.
    if (this.state.subview === "Oz-LogExpanded") {
      if (this.state.logExpandedIndex === 0) {
        newState.logExpandedIndex = 0
        newState.logExpanded = logs[0]
      } else {
        //we need to find the position of the logExpanded.
        for (let i = 0; i < logs.length; i = i + 1) {
          if (logs[i].id === this.state.logExpanded.id && logs[i].type === this.state.logExpanded.type) {
            newState.logExpandedIndex = i
            break;
          }
        }
      }
    }
    this.setState(newState, this.alertNewLog)

    return
  }

  //parses and adds data to the log for displaying.
  parseLog(log) {
    let t = log.timestamp.split(/[- :]/)
    let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
    log.timestampDate = d
    //set the state data on each log (expanded, selected, ...)
    log.expanded = false
    log.prettyPrint = false
    if (log.type === "Message") {
      log.contentTitle = log.code + " - " + log.message
      log.contentBody = log.extraData
      log.contentExtra = null
    } else if (log.line !== null) {
      log.contentTitle = "Line " + log.line + ", " + log.file
      if (log.message !== null) {
        log.contentBody = log.message
        log.contentExtra = log.extraData
      } else if (log.stackTrace !== null) {
        log.contentBody = log.stackTrace
        log.contentExtra = log.extraData
      } else {
        log.contentBody = ""
        log.contentExtra = log.extraData
      }
    } else {
      log.contentTitle = log.message !== null ? log.message : ""
      log.contentBody = (log.errorJSON !== null ? log.errorJSON : (log.extraData !== null ? log.extraData : ""))
      log.contentExtra = log.errorJSON === null && log.extraData !== null ? null : log.extraData
    }
    if (log.stackTraceCode !== null) {
      log.stackTraceCode = JSON.parse(log.stackTraceCode)
      Object.keys(log.stackTraceCode).forEach(function (key) {
        let lines = log.stackTraceCode[key]
        if (lines !== null) {
          let least = Number.MAX_SAFE_INTEGER
          let data = ""
          Object.keys(lines).forEach(function (line) {
            if (line < least) {
              least = line
            }
            if (data !== "") {
              data += "\n"
            }
            if (lines[line] === "") {
              data += "\t"
            } else {
              data += lines[line]
            }
          })
          log.stackTraceCode[key] = {
            "start": parseInt(least, 10),
            "data": data
          }
        }
      });
    }
    if (log.request !== null) {
      log.request = JSON.parse(log.request)
    }
    log.contentTitle = log.contentTitle !== null ? this.htmlToText(log.contentTitle) : log.contentTitle
    log.contentBody = log.contentBody !== null ? this.htmlToText(log.contentBody) : log.contentBody
    log.contentExtra = log.contentExtra !== null ? this.htmlToText(log.contentExtra) : log.contentExtra
    return log
  }

  //parses the stack trace into an array of stack trace lines.
  parseStackTrace(trace) {
    //split the lines and return them as an array
    let lines = trace.split("\n")
    return lines
  }

  expandStackTraceLine(line) {
    this.setState((old) => {
      let newLines = old.stackTraceLinesExpanded
      if (newLines.includes(line)) {
        newLines = newLines.filter((value, index, arr) => {
          return value !== line
        })
      } else {
        newLines.push(line)
      }
      console.log("expanded", newLines)
      return {
        stackTraceLinesExpanded: newLines
      }
    })
  }

  /*
  Changes the details tab
  */
  changeDetailsTab(newTab) {
    this.setState({
      detailsTab: newTab
    })
  }

  refreshLogging() {
    this.loadOzLogs(0)
  }

  selectOzLog(log) {
    if (log.type !== "Message" && log.type !== "Error") {
      this.expandLoggingWithLog(null, log)
      return
    }
    let logs = this.state.logs
    for (let i = 0; i < logs.length; i = i + 1) {
      if (logs[i].id === log.id && logs[i].type === log.type) {
        logs[i].expanded = !logs[i].expanded
        if (logs[i].expanded && logs[i].prettyPrint === false) {
          this.ozLogCode(null, logs[i], false)
        }
        break;
      }
    }
    this.setState({
      logs: logs
    })
  }

  expandLogging(updateURL = true) {
    this.setState({
      logFullScreen: true
    }, () => {
      $(window).scrollTop(0);
      if (updateURL) {
        updateWindowPath("/Apps/Oz/" + this.state.selectedAPI.id + "/Logs")
      }
    })
  }

  closeLoggingFullScreen() {
    this.setState({
      logFullScreen: false,
      stackTraceLinesExpanded: [],
      detailsTab: "stackTrace",
    }, () => {
      updateWindowPath("/Apps/Oz/" + this.state.selectedAPI.id)
    })
  }

  expandLoggingWithLog(e, log) {
    if (e !== null) {
      e.stopPropagation()
    }
    //get the current scroll position so that we can come back to this when we are done with the details.
    let lastScrollPosition = $(window).scrollTop();

    //find the index of this log
    let indi = 0;
    for (let i = 0; i < this.state.logs.length; i = i + 1) {
      if (this.state.logs[i].id === log.id && this.state.logs[i].type === log.type) {
        //we found the index of this log.
        indi = i;
        break;
      }
    }

    this.setState({
      logExpanded: log,
      logExpandedIndex: indi,
      subview: "Oz-LogExpanded",
      lastScrollPosition: lastScrollPosition,
      stackTraceLinesExpanded: [],
      detailsTab: "stackTrace",
    }, () => {
      $(window).scrollTop(0);
      //try to pretty print the result if necessary
      if (log.prettyPrint === false) {
        this.ozLogCode(null, log, false)
      }
      let logType = "U"
      if (log.type === "Message") {
        logType = "M"
      } else if (log.type === "Error") {
        logType = "E"
      }
      updateWindowPath("/Apps/Oz/" + this.state.selectedAPI.id + "/Logs/" + logType + log.id)
    })
  }

  expandSiblingLog(direction) {
    let newIndex = this.state.logExpandedIndex + direction
    if (newIndex < 0 || newIndex >= this.state.logs.length) {
      //no more logs to travel through
      return
    }
    let log = this.state.logs[newIndex]
    this.setState({
      logExpanded: log,
      logExpandedIndex: newIndex,
      stackTraceLinesExpanded: [],
      detailsTab: "stackTrace",
    }, () => {
      if (log.prettyPrint === false) {
        this.ozLogCode(null, log, false)
      }
      let logType = "U"
      if (log.type === "Message") {
        logType = "M"
      } else if (log.type === "Error") {
        logType = "E"
      }
      updateWindowPath("/Apps/Oz/" + this.state.selectedAPI.id + "/Logs/" + logType + log.id)
    })
    if (direction === 1) {
      this.props.showToast("Previous Log");
    } else {
      this.props.showToast("Next Log")
    }
  }

  closeLoggingWithLog() {
    this.setState({
      logExpanded: false,
      subview: "Oz-Selected",
      stackTraceLinesExpanded: [],
      detailsTab: "stackTrace",
    }, () => {
      $(window).scrollTop(this.state.lastScrollPosition);
      if (this.state.logFullScreen) {
        updateWindowPath("/Apps/Oz/" + this.state.selectedAPI.id + "/Logs")
      } else {
        updateWindowPath("/Apps/Oz/" + this.state.selectedAPI.id)
      }
    })
  }

  ozLogCopy(e, log) {
    e.stopPropagation()

    console.log(log)
    copy(log.contentBody)

    this.props.showToast("Copied Log Data")
  }

  ozLogLink(e, log) {
    e.stopPropagation()

    let url = "/Apps/Oz/" + this.state.selectedAPI.id + "/Logs/"
    if (log.type === "Message") {
      url = url + "M"
    } else if (log.type === "Error") {
      url = url + "E"
    } else {
      url = url + "U"
    }
    url = window.location.origin + url + log.id
    copy(url)

    this.props.showToast("Copied Link")
  }

  ozLogCode(e, log, showToast = true) {
    if (e !== null) {
      e.stopPropagation()
    }

    let logs = this.state.logs
    for (let i = 0; i < logs.length; i = i + 1) {
      if (logs[i].id === log.id && logs[i].type === log.type) {
        logs[i] = this.ozLogPrettyPrint(logs[i], showToast)
        if (logs[i].contentBodyPretty === undefined) {
          return
        }
        logs[i].prettyPrint = !logs[i].prettyPrint
        break;
      }
    }
    this.setState({
      logs: logs
    })

    if (showToast) {
      this.props.showToast("Pretty Print")
    }
  }

  ozLogPrettyPrint(log, showToast) {
    if (log.prettyPrint === false) {
      let json = null
      try {
        //don't parse a plain string, it makes no sense.
        if (log.contentBody.charAt(0) === '{' || log.contentBody.charAt(0) === '[') {
          json = JSON.parse(log.contentBody)
        }
      } catch(e) {
        //console.log("can't parse json", log.contentBody)
      }
      if (json === null) {
        //we can't parse this text
        if (showToast) {
          this.props.showToast("Can't Pretty Print")
        }
        return log
      }
      let html = prettyHtml(json, json.dimensions);
      log.contentBodyPretty = html
    }
    return log
  }

  ozChangeFilterMessages(isOn) {
    this.ozChangeFilter("messages", isOn)
  }

  ozChangeFilterErrors(isOn) {
    this.ozChangeFilter("errors", isOn)
  }

  ozChangeFilter(type, isOn) {
    let filters = this.state.logFilters
    filters[type] = isOn
    this.setState({
      logFilters: filters
    }, () => {
      this.loadOzLogs(0)
    })
  }

  /*
  Expands and shows details about this user.
  */
  ozShowUser(userID) {
    this.setState({
      subview: "Oz-UserDetails",
      userDetails: false
    }, () => {
      //load the user's details.
      console.log("loading user details", userID)
      API.callDarwinAPI("GET", "ozUserDetails/" + this.state.selectedAPI.id + "/" + userID, {}, (result) => {
        if ("error" in result) {
          this.setState({
            userDetails: false,
            subview: "Oz-LogExpanded"
          })
          console.log("error!")
          this.props.showError(result.error)
          return
        }
        //now set the user data
        this.setState({
          userDetails: result.data
        })
      })
    })
  }

  closeUserDetails() {
    this.setState({
      subview: "Oz-LogExpanded"
    })
  }

  /**
  Parses the html characters <>, and replaces
  them with &lt;&gt; for display.
  */
  htmlToText(text) {
    return text.replace(/</g, "&lt;").replace(/>/g, "&gt;")
  }

  /*
  Called to show or hide a form
  */
  showForm(formName, show) {
    let oldForm = false
    this.setState((old) => {
      oldForm = old.formShowing
      return {
        formShowing: show ? formName : false
      }
    }, () => {
      if (oldForm === "CreateNote") {
        //reload the notes
        this.loadStatusNotes()
      }
    })
  }

  /*
  Prints the provided object as text or renders it as json if necessary.
  */
  printObject(obj) {
    if (typeof obj === 'object' && obj !== null) {
      return (
        <div className="jsonDiv">
          {parse(prettyHtml(obj, obj.dimensions))}
        </div>
      )
    } else {
      return obj
    }
  }

  scrollToDoc(id) {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  render() {

    //formatting for logs
    let logDateFormat = {
      month: 'numeric',
      day: '2-digit',
      year: '2-digit',
      hour: "numeric",
      minute: "numeric"
    };

    //formatting for logs
    let logDateExpandedFormat = {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    };
    let logDateExpanded2Format = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    };
    let today = new Date()
    let todayDateString = today.toDateString()
    let yesterdayDate = new Date(today.getTime() - (24*60*60*1000))
    let yesterdayDateString = yesterdayDate.toDateString()

    //date object that was 5 minutes ago
    let timeM5 = new Date(today.getTime() - (5*60*1000))

    ArrowKeysReact.config({
      left: () => {
        if (this.state.subview === "Oz-LogExpanded") {
          this.expandSiblingLog(1)
        }
      },
      right: () => {
        if (this.state.subview === "Oz-LogExpanded") {
          this.expandSiblingLog(-1)
        }
      },
      up: () => {
      },
      down: () => {
      }
    })

    let logPagination = {
      currentPage: this.state.logPage,
      totalPages: this.state.logPageTotal,
      action: this.loadOzLogs
    }
    let realTimeProps = {
      callback: this.ozGetNewData
    }
    if (this.state.selectedAPI !== undefined) {
      realTimeProps.channel = "RTS_2_" + this.state.selectedAPI.id
    }

    let createSeparatorElements = [
      { type: "header",
        title: "Create Separator",
        description: "This will create a 'separator' log. This will allow you to visually create sections of logs so after you implement a fix, you'll be able to see if the same issues are still occurring."
      },
      {
        type: "text",
        name: "title",
        placeholder: "FIX",
        validation: "text",
        required: true,
        maximum: 255,
        title: "title",
        description: "the type of separation like FIX or TESTING.",
      },
      {
        type: "text",
        name: "description",
        placeholder: "Changed how the search works",
        validation: "text",
        required: true,
        maximum: 60000,
        title: "message",
        description: "the description of this separation like - Fixed the issue with the database.",
      },
      {
        type: "button",
        text: "Create Separator",
        buttonType: "submit"
      },
      {
        type: "button",
        text: "Cancel",
        buttonType: "cancel"
      },
    ]

    let createNoteElements = [
      { type: "header",
        title: "Create Note",
        description: "This will create a new note for the status page. This note will show up at the top of the status page. You can remove this note at a later time if you wish."
      },
      {
        type: "text",
        name: "title",
        placeholder: "Fix on the Way!",
        validation: "text",
        required: true,
        maximum: 255,
        title: "title",
        description: "A title for this note. Should provide some guidance as to the current state of the ecosystem.",
      },
      {
        type: "text",
        name: "body",
        placeholder: "Should be fixed within the hour and everything should work again!",
        validation: "text",
        required: true,
        maximum: 60000,
        title: "body",
        description: "The long description of the note. Details, details, details...",
      },
      {
        type: "button",
        text: "Create Note",
        buttonType: "submit"
      },
      {
        type: "button",
        text: "Cancel",
        buttonType: "cancel"
      },
    ]

    return (
      <span className={"AppsTab " + (this.props.darkMode ? "AppsTabDark" : "")}>
        { this.state.formShowing === "CreateSeparator" &&
          <Components.FullScreenForm method="POST" route={"ozSeparator/" + this.state.selectedAPI.id} finished={this.showForm.bind(this, false, false)} elements={createSeparatorElements} />
        }
        { this.state.formShowing === "CreateNote" &&
          <Components.FullScreenForm method="POST" route={"statusNote"} finished={this.showForm.bind(this, false, false)} elements={createNoteElements} />
        }
        { this.state.subview === "Home" &&
          <span>
            <div className="TabTitle">Apps</div>
            <div className="TabSubtitle" style={{paddingTop:"30px"}}>Darwin Applications</div>
            { this.props.apps !== false && this.props.apps.length === 0 &&
              <div className="TabEmptyDescription">
                You don't have access to any Darwin Apps.<br/>
                Contact your organization's administrator to gain access.
              </div>
            }
            { this.props.apps.length > 0 &&
              <span>
                <div className="appContainer">
                  <PoseGroup animateOnMount preEnterPose="original">
                    { this.props.apps.map((app, iter) => (
                      <SlideUpDiv key={"appDivi" + iter} iter={iter} className="appDiv">
                        <div className="appDivBox" onClick={() => this.selectApp(app)}>
                          <div className="appDivLogo">
                            <img src={app.logo} alt="" />
                          </div>
                          <Textfit className="appDivName" mode="single" min={4} max={16}>
                            {app.name}
                          </Textfit>
                        </div>
                        <div className="appDivDescription">
                          {app.description}
                        </div>
                      </SlideUpDiv>
                    ))}
                  </PoseGroup>
                </div>
              </span>
            }
          </span>
        }
        
        {/* Oz Logging */}
        { this.state.subview === "Oz-Home" &&
          <span>
            <div className="TabTitle">Oz</div>
            <div className="TabSubdescription">
              Welcome to the land of Oz. We'll help debug your app through real time logging.
              We'll track bugs and stack traces for crashes, and help you land a house on the wicked witch
              (the East one that is, the West one we'd need water and well... computers and water just don't mix).
            </div>
            <div className="TabSubtitle" style={{paddingTop:"30px"}}>Applications</div>
            <div className="TabSubdescription">
              Select an application below to view the crashes and logs associated with that application.
            </div>

            { this.state.apisLoading === true &&
              <Components.LoadingIndicator style={{paddingTop:"100px"}} />
            }
            { this.state.apis !== false && this.state.apis.length === 0 &&
              <div className="TabEmptyDescription">
                You don't have access to any applications.<br/>
                Contact your organization's administrator to gain access.
              </div>
            }
            { this.state.apis.length > 0 &&
              <span>
                <div className="apiContainer">
                  <PoseGroup animateOnMount preEnterPose="original">
                    { this.state.apis.map((app, iter) => (
                      <SlideUpDiv key={"apiDivi" + iter} iter={iter} className="apiDivContainer" onClick={() => this.selectOzAPI(app)}>
                        <div className="apiDiv">
                          <div className="apiDivLogo">
                            <img src={app.icon} alt="" />
                          </div>
                          <Textfit className="apiDivName" mode="single" min={4} max={16}>
                            {app.name}
                          </Textfit>
                        </div>
                      </SlideUpDiv>
                    ))}
                  </PoseGroup>
                </div>
              </span>
            }
          </span>
        }
        { this.state.subview === "Oz-Selected" &&
          <span>
            { this.state.logFullScreen === false &&
              <span>
                <div className="TabTitle">
                  Oz - {this.state.selectedAPI.name}
                  <Components.DualImage image={this.props.darkMode ? ImageHomeOutlineWhite : ImageHomeOutline} hoverimage={this.props.darkMode ? ImageHomeFilledWhite : ImageHomeFilled} style={{cursor:"pointer", height:"30px", marginLeft:"20px"}} onClick={() => this.ozHome()} />
                </div>
                <div className="TabSubdescription">
                  Welcome to the land of Oz. We'll help debug your app through real time logging.
                  We'll track bugs and stack traces for crashes, and help you land a house on the wicked witch
                  (the East one that is, the West one we'd need water and well... computers and water just don't mix).
                </div>
                <div className="TabSubtitle" style={{paddingTop:"30px"}}>
                  Logging
                  <Components.DualImage data-tip="Refresh Data" image={ImageRefresh} hoverimage={ImageRefresh2} style={{cursor:"pointer", height: "22px", maxWidth:"20px", marginLeft: "20px"}} onClick={() => this.refreshLogging()} />
                  <Components.DualImage data-tip="View Logs Fullscreen" image={this.props.darkMode ? ImageExpandWhite : ImageExpand} hoverimage={this.props.darkMode ? ImageExpand4White : ImageExpand4} style={{cursor:"pointer", height: "20px", marginLeft: "20px"}} onClick={() => this.expandLogging()} />
                </div>
                <div className="TabSubdescription">
                  The messages displayed here are sent from your Darwin Application.
                  These could be log messages or errors. Errors will also show up categorized into issues above.
                  If you tap on the expand button next to this title you can view these logs in greater detail.
                </div>
              </span>
            }
            { this.state.logFullScreen === true &&
              <span>
                { !this.props.darkMode &&
                  <BackgroundSet color="white" priority="1" bindMethod={this.props.setBackgroundColor} />
                }
                { this.props.darkMode &&
                  <BackgroundSet color="#10151F" priority="1" bindMethod={this.props.setBackgroundColor} />
                }
                {/* The header with an X button for getting out of the Log Expanded subview */}
                <div className="OzTop">
                  <div className="OzTitle" style={{textAlign:"right"}}>
                    Oz - {this.state.selectedAPI.name}
                  </div>
                  <Components.DualImage image={ImageXHollow} hoverimage={ImageXFilled} style={{cursor:"pointer", margin:"0px 20px"}} onClick={() => this.closeLoggingFullScreen()}/>
                  <div className="OzTitle">
                    Logging
                  </div>
                </div>
              </span>
            }
            <div className="iOSSwitchBlock" style={{marginTop:"10px", marginBottom:"10px"}}>
              <div className="iOSSwitchBlockGroup">
                <IOSSwitch handleChange={this.handleSoundSwitch} checked={this.state.notificationSoundEnabled} color="blue" darkMode={this.props.darkMode} />
                <div className="details" style={{marginRight:"25px"}}>
                  Enable Notification Sounds
                </div>
              </div>
            </div>
            <div className="iOSSwitchBlock" style={{marginTop:"10px", marginBottom:"10px"}}>
              <div className="iOSSwitchBlockGroup">
                <IOSSwitch handleChange={this.ozChangeFilterMessages} checked={this.state.logFilters.messages} darkMode={this.props.darkMode}/>
                <div className="details" style={{marginRight:"25px"}}>
                  Messages
                </div>
              </div>
              <div className="iOSSwitchBlockGroup">
                <IOSSwitch handleChange={this.ozChangeFilterErrors} checked={this.state.logFilters.errors} darkMode={this.props.darkMode}/>
                <div className="details" style={{marginRight:"25px"}}>
                  Errors
                </div>
              </div>
              <Components.MaterialButton type="White" size="Large" onClick={this.showForm.bind(this, "CreateSeparator", true)}>
                Create Separator
              </Components.MaterialButton>
            </div>

            { this.state.logsLoading === true &&
              <Components.LoadingIndicator style={{paddingTop:"30px"}} />
            }
            { this.state.logs !== false && this.state.logs.length === 0 &&
              <div className="TabEmptyDescription">
                { (this.state.logFilters.messages === false && this.state.logFilters.errors === false) ?
                  <span>Turn on the Messages or Errors filter to view logs.</span>
                  : <span>No logs found for this application and filter set.</span>
                }
              </div>
            }
            { this.state.logs.length > 0 &&
              <span>
                <div className="logContainer">
                  <Pagination {...logPagination} style={{marginTop:"0px", marginBottom:"10px"}}/>
                  <PoseGroup animateOnMount preEnterPose="original">
                    { this.state.logs.map((log, iter) => (
                      <SlideUpDivTogether key={"logDivi" + iter} iter={iter} className={
                          (log.type === "Message" || log.type === "Error") ?
                          ("logDiv " +
                           ((log.type === "Message" && log.timestampDate > timeM5) ? "logDivMessage " :
                           ((log.type === "Error" && log.timestampDate > timeM5) ? "logDivError " : "")) +
                           (log.expanded === true ? "logDivExpanded" : "")
                         ) : "logDiv" + log.type
                        } onClick={() => this.selectOzLog(log)}>
                        { (log.type === "Message" || log.type === "Error") &&
                          <div className="leftBlock">
                            <Textfit className="versionCircle" mode="single" min={4} max={16}>
                              {log.appVersion}
                            </Textfit>
                            <div className="versionDetails">
                              <div className="logType">
                                {log.type}
                              </div>
                              <div className="logClient">
                                {log.clientID}
                              </div>
                            </div>
                            { log.expanded === true &&
                              <div className="leftButtons">
                                <div data-tip="copy the log data" data-for={"logToolTip" + iter} className="leftButton" onClick={(e) => this.ozLogCopy(e, log)}>
                                  <img src={this.props.darkMode ? ImageCopyWhite : ImageCopy} alt="Copy"/>
                                </div>
                                <div data-tip="copy link to this log" data-for={"logToolTip" + iter} className="leftButton" onClick={(e) => this.ozLogLink(e, log)}>
                                  <img src={this.props.darkMode ? ImageURLWhite : ImageURL} alt="Copy URL To This Log"/>
                                </div>
                                <div data-tip="pretty print the log data" data-for={"logToolTip" + iter} className="leftButton" onClick={(e) => this.ozLogCode(e, log)}>
                                  <img src={this.props.darkMode ? ImageCodeWhite : ImageCode} alt="Pretty Print"/>
                                </div>
                                <ReactTooltip id={"logToolTip" + iter} type="light" />
                              </div>
                            }
                          </div>
                        }
                        { (log.type === "Message" || log.type === "Error") &&
                          <div className="borderBlock">&nbsp;</div>
                        }
                        { (log.type === "Message" || log.type === "Error") &&
                          <div className="contentBlock">
                            <div className="contentTitle">
                              {log.contentTitle}
                            </div>
                            <div className="contentBody">
                              { log.prettyPrint === false &&
                                parse(log.contentBody.split("\n").join("<br/>"))
                              }
                              { log.prettyPrint === true &&
                                <div className="jsonDiv">
                                  {parse(log.contentBodyPretty)}
                                </div>
                              }
                            </div>
                          </div>
                        }
                        { (log.type === "Message" || log.type === "Error") &&
                          <div className="borderBlock">&nbsp;</div>
                        }
                        { (log.type === "Message" || log.type === "Error") &&
                          <div className="rightBlock">
                            <Components.DualImage image={this.props.darkMode ? ImageExpandWhite : ImageExpand} hoverimage={this.props.darkMode ? ImageExpand4White : ImageExpand4} onClick={(e) => this.expandLoggingWithLog(e, log)} />
                            <br/>
                            {
                              log.timestampDate.toLocaleDateString("en-US", logDateFormat).split("/").join("-")
                            }
                          </div>
                        }
                        { ["Separator", "Deployment"].includes(log.type) &&
                          <div className="contentImage">
                            <img src={
                              log.type === "Separator" ?
                               (ImageFixLight)
                              : (ImageRocketLight)
                             } alt="Separator" />
                          </div>
                        }
                        { ["Separator", "Deployment"].includes(log.type) &&
                          <div className="contentBlock">
                            <div className="contentTitle">
                              {log.contentTitle}
                            </div>
                            { log.type === "Deployment" &&
                              <div className="contentVersion">
                                {"v" + log.appVersion + " @ " + log.timestampDate.toLocaleDateString("en-US", logDateFormat).split("/").join("-")}
                              </div>
                            }
                            <div className="contentBody">
                              { log.prettyPrint === false &&
                                parse(log.contentBody.split("\n").join("<br/>"))
                              }
                              { log.prettyPrint === true &&
                                <div className="jsonDiv">
                                  {parse(log.contentBodyPretty)}
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </SlideUpDivTogether>
                    ))}
                  </PoseGroup>
                  <Pagination {...logPagination} style={{marginTop:"10px", marginBottom:"10px"}}/>
                </div>
              </span>
            }
            { this.state.logFullScreen === false &&
              <span>
                <div className="TabSubtitle" style={{paddingTop:"30px"}}>Issues</div>
                <div className="TabSubdescription">
                  Issues will show recurring logs so you can find commonalities between them.
                </div>
                <div className="TabEmptyDescription">
                  issues yet to be implemented
                </div>
              </span>
            }
          </span>
        }
        { this.state.subview === "Oz-LogExpanded" &&
          <div className={
            (this.state.logExpanded.type === "Message" || this.state.logExpanded.type === "Error") ?
             "OzExpanded " +
             ((this.state.logExpanded.type === "Message" && this.state.logExpanded.timestampDate > timeM5) ? "ozExpandedMessage " :
             ((this.state.logExpanded.type === "Error" && this.state.logExpanded.timestampDate > timeM5) ? "ozExpandedError " : ""))
            : ("OzExpanded OzExpanded" + this.state.logExpanded.type)
          } {...ArrowKeysReact.events} tabIndex="1" ref={input => input && input.focus()}>
            {/* This React Block turns the body's background white */}
            { !this.props.darkMode &&
              <BackgroundSet color="white" priority="1" bindMethod={this.props.setBackgroundColor} />
            }
            { this.props.darkMode &&
              <BackgroundSet color="#10151F" priority="1" bindMethod={this.props.setBackgroundColor} />
            }

            {/* The header with an X button for getting out of the Log Expanded subview */}
            <div className="OzTop">
              <div className="OzTitle" style={{textAlign:"right"}}>
                Oz - {this.state.selectedAPI.name}
              </div>
              <Components.DualImage image={ImageXHollow} hoverimage={ImageXFilled} style={{cursor:"pointer", margin:"0px 20px"}} onClick={() => this.closeLoggingWithLog()}/>
              <div className="OzTitle">
                Logging
              </div>
            </div>

            {/* The arrows for traversing through logs */}
            <div className="OzArrows">
              <div className={"OzArrow " + (this.state.logExpandedIndex < (this.state.logs.length - 1) ? "OzArrowAvailable" : "")} style={{textAlign:"right"}} onClick={() => this.expandSiblingLog(1)}>
                &#60; Previous
              </div>
              <div className="OzTimestamp">
                { todayDateString === this.state.logExpanded.timestampDate.toDateString() &&
                  <span>Today</span>
                }
                { yesterdayDateString === this.state.logExpanded.timestampDate.toDateString() &&
                  <span>Yesterday</span>
                }
                { todayDateString !== this.state.logExpanded.timestampDate.toDateString() && yesterdayDateString !== this.state.logExpanded.timestampDate.toDateString() &&
                  this.state.logExpanded.timestampDate.toLocaleDateString("en-US", logDateExpandedFormat)
                }
                <br />
                { this.state.logExpanded.timestampDate.toLocaleTimeString("en-US", logDateExpanded2Format) }
              </div>
              <div className={"OzArrow " + (this.state.logExpandedIndex > 0 ? "OzArrowAvailable" : "")} onClick={() => this.expandSiblingLog(-1)}>
                Next &#62;
              </div>
            </div>

            {/* The type of log, the client, the client/api version */}
            <div className="OzType">
              { this.state.logExpanded.type }
            </div>

            {/* Content for Separators */}
            { ["Separator", "Deployment"].includes(this.state.logExpanded.type) &&
              <div className={this.state.logExpanded.type === "Separator" ? "OzSeparatorFull" : "OzDeploymentFull"}>
                <div className="OzSeparatorImage">
                  <img src={
                    this.state.logExpanded.type === "Separator" ?
                     (ImageFixLight)
                    : (ImageRocketLight)
                   } alt="Separator" />
                </div>
                <div className="OzSeparatorTitle">
                  {this.state.logExpanded.contentTitle}
                </div>
                { this.state.logExpanded.type === "Deployment" &&
                  <div className="OzSeparatorVersion">
                    {"v" + this.state.logExpanded.appVersion + " @ " + this.state.logExpanded.timestampDate.toLocaleDateString("en-US", logDateFormat).split("/").join("-")}
                  </div>
                }
                <div className="OzSeparatorMessage">
                  {this.state.logExpanded.contentBody}
                </div>
              </div>
            }

            {/* Content for Messages and Errors */}
            { ["Message", "Error"].includes(this.state.logExpanded.type) &&
              <span>
                <div className="OzClient">
                  { this.state.logExpanded.clientID }
                </div>
                { this.state.logExpanded.appVersion !== null &&
                  <div className="OzVersion">
                    { "v " + this.state.logExpanded.appVersion }
                  </div>
                }
                { this.state.logExpanded.userID !== null &&
                  <div className="OzUserID">
                    User ID
                    <div className="OzUserIDLink" onClick={this.ozShowUser.bind(this, this.state.logExpanded.userID)}>
                      { this.state.logExpanded.userID }
                    </div>
                  </div>
                }
                {/* The device, browser, ... and code if available */}
                <div className="OzDetails">
                  { "" +
                    (this.state.logExpanded.processType !== null ? this.state.logExpanded.processType + " - " : "") +
                    (this.state.logExpanded.platform !== null ? this.state.logExpanded.platform + " - " : "") +
                    (this.state.logExpanded.systemVersion !== null ? "System - " + this.state.logExpanded.systemVersion : "")
                  }
                </div>
                <div className="OzCode">
                  { "Code - " + (this.state.logExpanded.code !== null ? this.state.logExpanded.code : "0") }
                </div>

                {/* The title, buttons, and message */}
                <div className="OzMessage">
                  { this.state.logExpanded.contentTitle }
                  { this.state.logExpanded.stackTraceCode !== null && this.state.logExpanded.stackTraceCode['firstLine'] &&
                    <div className="OzStackLinePreview">
                      <Components.CodeEditor language="PHP" theme={this.props.darkMode ? "material" : "neo"} firstLineNumber={this.state.logExpanded.stackTraceCode['firstLine'].start} readOnly={true} annotations={false}
                        codeUpdated={() => {}}
                        code={this.state.logExpanded.stackTraceCode['firstLine'].data}
                        highlightLine={this.state.logExpanded.line}
                      >
                      </Components.CodeEditor>
                    </div>
                  }
                </div>
                <div className="OzButtons">
                  <div data-tip="copy the log data" data-for="logExpandedToolTip" className="OzButton" onClick={(e) => this.ozLogCopy(e, this.state.logExpanded)}>
                    <img src={this.props.darkMode ? ImageCopyWhite : ImageCopy} alt="Copy"/>
                  </div>
                  <div data-tip="copy link to this log" data-for="logExpandedToolTip" className="OzButton" onClick={(e) => this.ozLogLink(e, this.state.logExpanded)}>
                    <img src={this.props.darkMode ? ImageURLWhite : ImageURL} alt="Copy URL To This Log"/>
                  </div>
                  <div data-tip="pretty print the log data" data-for="logExpandedToolTip" className="OzButton" onClick={(e) => this.ozLogCode(e, this.state.logExpanded)}>
                    <img src={this.props.darkMode ? ImageCodeWhite : ImageCode} alt="Pretty Print"/>
                  </div>
                  <ReactTooltip id="logExpandedToolTip" type="light" />
                </div>
                { this.state.logExpanded.contentBody !== this.state.logExpanded.stackTrace &&
                  <div className="OzMessageDetails">
                    { this.state.logExpanded.prettyPrint === false &&
                      parse(this.state.logExpanded.contentBody.split("\n").join("<br/>"))
                    }
                    { this.state.logExpanded.prettyPrint === true &&
                      <div className="jsonDiv">
                        {parse(this.state.logExpanded.contentBodyPretty)}
                      </div>
                    }
                  </div>
                }

                {/* The extra data */}
                { this.state.logExpanded.contentExtra !== null &&
                  <span>
                    <div className="OzMessage">
                      Extra Data
                    </div>
                    <div className="OzMessageDetails">
                      { parse(this.state.logExpanded.contentExtra.split("\n").join("<br/>")) }
                    </div>
                  </span>
                }

                {/* The stack trace and request data if available */}
                { (this.state.logExpanded.stackTrace !== null || this.state.logExpanded.request !== null) &&
                  <span>
                    <div className="OzExtraTabs">
                      <div className={"OzExtraTab " + (this.state.detailsTab === "stackTrace" ? "OzExtraTabSelected" : "")} onClick={this.changeDetailsTab.bind(this, "stackTrace")}>
                        Stack Trace
                      </div>
                      <div className={"OzExtraTab " + (this.state.detailsTab === "request" ? "OzExtraTabSelected" : "")} onClick={this.changeDetailsTab.bind(this, "request")}>
                        Request
                      </div>
                    </div>
                    { this.state.detailsTab === "request" &&
                      <span>
                        <div className="OzMessageDetails">
                          { this.state.logExpanded.request === null &&
                            <div className="OzMessageDetailsNone">
                              There are no details about this request.
                            </div>
                          }
                          { this.state.logExpanded.request !== null &&
                            <div className="OzRequest">
                              {/* Route Parameters */}
                              <div className="OzRequestHeader">
                                Route
                              </div>
                              <div className="OzRequestParam">
                                <div className="OzRequestKey">
                                  HTTP Method
                                </div>
                                <div className="OzRequestValue">
                                  { this.state.logExpanded.request.httpMethod }
                                </div>
                              </div>
                              <div className="OzRequestParam">
                                <div className="OzRequestKey">
                                  URL
                                </div>
                                <div className="OzRequestValue">
                                  { this.state.logExpanded.request.url }
                                </div>
                              </div>
                              <div className="OzRequestParam">
                                <div className="OzRequestKey">
                                  Client IP Address
                                </div>
                                <div className="OzRequestValue">
                                  { this.state.logExpanded.request.clientIP }
                                </div>
                              </div>

                              {/* Data / Parameters that were passed */}
                              <div className="OzRequestHeader">
                                Data
                              </div>
                              <div className="OzRequestParam">
                                { (!this.state.logExpanded.request.parameters || this.state.logExpanded.request.parameters.length === 0) &&
                                  <div className="OzRequestValue">
                                    No Data
                                  </div>
                                }
                                { this.state.logExpanded.request.parameters &&
                                  <span>
                                    { Object.keys(this.state.logExpanded.request.parameters).map((key, i) => (
                                      <span key={"param_" + i}>
                                        <div className="OzRequestKey">
                                          {key}
                                        </div>
                                        <div className="OzRequestValue">
                                          { this.printObject(this.state.logExpanded.request.parameters[key]) }
                                        </div>
                                      </span>
                                    ))}
                                  </span>
                                }
                              </div>

                              {/* Resolved Attributes */}
                              <div className="OzRequestHeader">
                                Resolved Attributes
                              </div>
                              <div className="OzRequestParam">
                                { (!this.state.logExpanded.request.resolvedAttributes || this.state.logExpanded.request.resolvedAttributes.length === 0) &&
                                  <div className="OzRequestValue">
                                    No Resolved Attributes
                                  </div>
                                }
                                { this.state.logExpanded.request.resolvedAttributes &&
                                  <span>
                                    { Object.keys(this.state.logExpanded.request.resolvedAttributes).map((key, i) => (
                                      <span key={"ra_" + i}>
                                        <div className="OzRequestKey">
                                          {key}
                                        </div>
                                        <div className="OzRequestValue">
                                          { this.printObject(this.state.logExpanded.request.resolvedAttributes[key]) }
                                        </div>
                                      </span>
                                    ))}
                                  </span>
                                }
                              </div>

                              {/* Cookies */}
                              <div className="OzRequestHeader">
                                Cookies
                              </div>
                              <div className="OzRequestParam">
                                { (!this.state.logExpanded.request.cookies || this.state.logExpanded.request.cookies.length === 0) &&
                                  <div className="OzRequestValue">
                                    No Cookies
                                  </div>
                                }
                                { this.state.logExpanded.request.cookies &&
                                  <span>
                                    { Object.keys(this.state.logExpanded.request.cookies).map((key, i) => (
                                      <span key={"cookie_" + i}>
                                        <div className="OzRequestKey">
                                          {key}
                                        </div>
                                        <div className="OzRequestValue">
                                          {this.state.logExpanded.request.cookies[key]}
                                        </div>
                                      </span>
                                    ))}
                                  </span>
                                }
                              </div>

                              {/* Headers */}
                              <div className="OzRequestHeader">
                                Headers
                              </div>
                              <div className="OzRequestParam">
                                { (!this.state.logExpanded.request.headers || this.state.logExpanded.request.headers.length === 0) &&
                                  <div className="OzRequestValue">
                                    No Headers
                                  </div>
                                }
                                { this.state.logExpanded.request.headers &&
                                  <span>
                                    { Object.keys(this.state.logExpanded.request.headers).map((key, i) => (
                                      <span key={"header_" + i}>
                                        <div className="OzRequestKey">
                                          {key}
                                        </div>
                                        <div className="OzRequestValue">
                                          {this.state.logExpanded.request.headers[key]}
                                        </div>
                                      </span>
                                    ))}
                                  </span>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </span>
                    }
                    { this.state.detailsTab === "stackTrace" &&
                      <span>
                        <div className="OzMessageDetails">
                          { this.state.logExpanded.stackTrace === null &&
                            <div className="OzMessageDetailsNone">
                              There is no stack trace for this log.
                            </div>
                          }
                          { this.state.logExpanded.stackTrace !== null &&
                            <span>
                              { this.parseStackTrace(this.state.logExpanded.stackTrace).map((line, i) => (
                                <div key={"line" + i} className={"OzStackTraceLine " +
                                  (line.includes("/var/www/html/apis/") && !line.includes("Slim\\") ?
                                    ( this.state.logExpanded.stackTraceCode && this.state.logExpanded.stackTraceCode[parseInt(line.split(" ")[0].substring(1), 10)] ? "OzStackTraceLineEnhanced" : "OzStackTraceLineEnhancedGray")
                                  : "")
                                }>
                                  <div className={"OzStackTraceLineLine"} onClick={ (line.includes("/var/www/html/apis/") && !line.includes("Slim\\") ?
                                    this.expandStackTraceLine.bind(this, parseInt(line.split(" ")[0].substring(1), 10))
                                    : undefined)
                                  }>
                                    {line}
                                  </div>
                                  {/* SHOW THE STACK TRACE CODE HERE IF IT EXISTS */}
                                  { line.includes("/var/www/html/apis/") && !line.includes("Slim\\") && this.state.logExpanded.stackTraceCode && !this.state.stackTraceLinesExpanded.includes(parseInt(line.split(" ")[0].substring(1), 10)) &&
                                    <div className="OzStackLinePreview">
                                      <Components.CodeEditor language="PHP" theme={this.props.darkMode ? "material" : "neo"} firstLineNumber={this.state.logExpanded.stackTraceCode[parseInt(line.split(" ")[0].substring(1), 10)].start} readOnly={true} annotations={false}
                                        codeUpdated={() => {}}
                                        code={this.state.logExpanded.stackTraceCode[parseInt(line.split(" ")[0].substring(1), 10)].data}
                                        highlightLine={line.split(/[()]/).length > 1 ? parseInt(line.split(/[()]/)[1], 10) : false}
                                      >
                                      </Components.CodeEditor>
                                    </div>
                                  }
                                </div>
                              ))}
                            </span>
                          }
                        </div>
                      </span>
                    }
                  </span>
                }
              </span>
            }
          </div>
        }
        { (this.state.subview === "Oz-Selected" || this.state.subview === "Oz-LogExpanded") &&
          <RealTimeSocket {...realTimeProps} />
        }
        { this.state.subview === "Oz-UserDetails" &&
          <span>
            {/* This React Block turns the body's background white */}
            { !this.props.darkMode &&
              <BackgroundSet color="white" priority="1" bindMethod={this.props.setBackgroundColor} />
            }
            { this.props.darkMode &&
              <BackgroundSet color="#10151F" priority="1" bindMethod={this.props.setBackgroundColor} />
            }
            {/* The header with an X button for getting out of the User Details subview */}
            <div className="OzTop">
              <div className="OzTitle" style={{textAlign:"right"}}>
                Oz - {this.state.selectedAPI.name}
              </div>
              <Components.DualImage image={ImageXHollow} hoverimage={ImageXFilled} style={{cursor:"pointer", margin:"0px 20px"}} onClick={this.closeUserDetails}/>
              <div className="OzTitle">
                Logging
              </div>
            </div>

            { this.state.userDetails === false &&
              <Components.LoadingIndicator style={{paddingTop:"30px"}} />
            }
            { this.state.userDetails !== false &&
              <div className="OzUserDetails">
                <img className="OzUserDetailsImage" src={this.state.userDetails.profilePicture} alt="user profile"/>
                <div className="OzUserDetailsName">
                  {this.state.userDetails.name}
                </div>
                { this.state.userDetails.email !== false &&
                  <div className="OzUserDetailsContact">
                    {this.state.userDetails.email}
                  </div>
                }
                { this.state.userDetails.phoneNumber !== false &&
                  <div className="OzUserDetailsContact">
                    {this.state.userDetails.phoneNumber}
                  </div>
                }
              </div>
            }
          </span>
        }
        
        {/* Documentation */}
        { this.state.subview === "Documentation-Home" &&
          <span>
            <div className="TabTitle">Documentation</div>
            <div className="TabSubdescription">
              Welcome to the docs. We'll help you search through your APIs to figure out what they're doing
              and how you can use them.
            </div>
            <div className="TabSubtitle" style={{paddingTop:"30px"}}>Applications</div>
            <div className="TabSubdescription">
              Select an application below to view the documentation.
            </div>

            { this.state.apisLoading === true &&
              <Components.LoadingIndicator style={{paddingTop:"100px"}} />
            }
            { this.state.apis !== false && this.state.apis.length === 0 &&
              <div className="TabEmptyDescription">
                You don't have access to any applications.<br/>
                Contact your organization's administrator to gain access.
              </div>
            }
            { this.state.apis.length > 0 &&
              <span>
                <div className="apiContainer">
                  <PoseGroup animateOnMount preEnterPose="original">
                    { this.state.apis.map((app, iter) => (
                      <SlideUpDiv key={"apiDivi" + iter} iter={iter} className="apiDivContainer" onClick={() => this.selectDocumentationAPI(app)}>
                        <div className="apiDiv">
                          <div className="apiDivLogo">
                            <img src={app.icon} alt="" />
                          </div>
                          <Textfit className="apiDivName" mode="single" min={4} max={16}>
                            {app.name}
                          </Textfit>
                        </div>
                      </SlideUpDiv>
                    ))}
                  </PoseGroup>
                </div>
              </span>
            }
          </span>
        }
        { this.state.subview === "Documentation-Selected" &&
          <span>
            { this.state.logFullScreen === false &&
              <span>
                <div className="TabTitle">
                  Docs - {this.state.selectedAPI.name}
                  <Components.DualImage image={this.props.darkMode ? ImageHomeOutlineWhite : ImageHomeOutline} hoverimage={this.props.darkMode ? ImageHomeFilledWhite : ImageHomeFilled} style={{cursor:"pointer", height:"30px", marginLeft:"20px"}} onClick={() => this.documentationHome()} />
                </div>
              </span>
            }
            { this.state.documentationLoading === true &&
              <Components.LoadingIndicator style={{paddingTop:"30px"}} />
            }
            { this.state.documentationLoading === false && this.state.documentation === false &&
              <div className="TabEmptyDescription">
                No documentation has been generated for this API.
              </div>
            }
            { this.state.documentationLoading === false && this.state.documentation !== false &&
              <div className="DocsBody">
                <div className="DocsLeft">
                  <div className="DocsLeftTitle">
                    ENDPOINTS
                  </div>
                  <div className="DocsLeftSection">
                    { this.state.documentation.methods.map((method, count) => (
                      <div className="Method" key={method.id} onClick={this.scrollToDoc.bind(this, "method_" + count)}>
                        <div className="MethodType">
                          {method.type}
                        </div>
                        <div>
                          {method.name}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="DocsLeftTitle">
                    HELPER FUNCTIONS
                  </div>
                  <div className="DocsLeftSection">
                    { this.state.documentation.helperFunctions.map((hf, count) => (
                      <div className="HelperFunction" key={hf.id} onClick={this.scrollToDoc.bind(this, "function_" + count)}>
                        {hf.name}()
                      </div>
                    ))}
                  </div>
                </div>
                <div className="DocsRight">
                  {/* API Endpoints */}
                  <div className="DocsRightSectionName">
                    API Endpoints
                  </div>
                  <div className="DocsRightSection">
                    { this.state.documentation.methods.map((method, count) => (
                      <div className="DocsRightMethod" key={method.id} id={"method_" + count}>
                        <div className="DocsRightMethodTop">
                          <div className={"DocsRightMethodType DocsRightMethodType" + method.type}>
                            {method.type}
                          </div>
                          <div className="DocsRightMethodName">
                            {method.originalName}
                          </div>
                        </div>
                        <div className="DocsRightMethodComment">
                          {method.comment.length > 0 ? method.comment : "No documentation"}
                        </div>
                        
                        {/* Middleware */}
                        <div className="DocsRightMethodSection">
                          Middleware
                        </div>
                        <div className="DocsRightMethodMiddlewares">
                          {method.middleware.map((mw) => (
                            <div className="DocsRightMethodMiddleware" key={mw}>
                              {mw}
                            </div>
                          ))}
                        </div>
                        
                        {/* Parameters */}
                        <div className="DocsRightMethodSection">
                          Parameters
                        </div>
                        <div className="DocsRightHelperFunctionParameters">
                          {method.parameters.map((p) => (
                            <div className="DocsRightHelperFunctionParameter" key={p.name}>
                              <div className="DocsRightHelperFunctionParameterTop">
                                <div className="DocsRightHelperFunctionParameterName">
                                  {p.name}
                                </div>
                                <div className="DocsRightHelperFunctionParameterType">
                                  {p.v}
                                </div>
                                { p.r === false &&
                                  <div className="DocsRightHelperFunctionParameterOptional">
                                    optional
                                  </div>
                                }
                              </div>
                              {(p["min"] || p["max"] || p.c) &&
                                <div className="DocsRightHelperFunctionParameterBody">
                                  {p["min"] !== undefined &&
                                    <div className="DocsRightHelperFunctionParameterBodyMinMax">
                                      Min <span>{p["min"]}</span>
                                    </div>
                                  }
                                  {p["max"] !== undefined &&
                                    <div className="DocsRightHelperFunctionParameterBodyMinMax">
                                      Max <span>{p["max"]}</span>
                                    </div>
                                  }
                                  {p.c &&
                                    <div className="DocsRightHelperFunctionParameterBodyChoices">
                                      <div className="DocsRightHelperFunctionParameterBodyChoicesTitle">
                                        Choices
                                      </div>
                                      {p.c.map((choice) => (
                                        <div className="DocsRightHelperFunctionParameterBodyChoice" key={"choice_" + choice}>
                                          {choice}
                                        </div>
                                      ))}
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  {/* Helper Functions */}
                  <div className="DocsRightSectionName">
                    Helper Functions
                  </div>
                  <div className="DocsRightSection">
                    { this.state.documentation.helperFunctions.map((hf, count) => (
                      <div className="DocsRightHelperFunction" id={"function_" + count}>
                        <div className="DocsRightHelperFunctionName">
                          <div className="DocsRightHelperFunctionNameText">
                            {hf.name}(
                          </div>
                          {/* List of variables */}
                          { hf.inputs.map((input) => (
                            <div className="DocsRightHelperFunctionVariable" key={input}>
                              {input}
                            </div>
                          ))}
                          <div className="DocsRightHelperFunctionNameTextEnd">
                            )
                          </div>
                        </div>
                        <div className="DocsRightHelperFunctionComment">
                          {hf.comment.length > 0 ? hf.comment : "No documentation"}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          </span>
        }
        
        {/* Nature */}
        { this.state.subview === "Nature-Home" &&
          <span>
            <div className="TabSubtitle" style={{paddingTop:"30px"}}>Nature App TODO</div>
          </span>
        }

        {/* Status */}
        { this.state.subview === "Status-Home" &&
          <span>
            <div className="TabSubtitle" style={{paddingTop:"30px"}}>Status App</div>
            { this.state.statusNotesLoading === true &&
              <Components.LoadingIndicator style={{paddingTop:"100px"}} />
            }
            { !this.state.statusNotesLoading &&
              <span>
                {/* Display a button to create a new note */}
                <Components.MaterialButton type="White" size="Large" onClick={this.showForm.bind(this, "CreateNote", true)}>
                  Create Note
                </Components.MaterialButton>
                {/* Display the list of notes */}
                <div className="StatusNotesContainer">
                  { this.state.statusNotes.map((note, i) => (
                    <div key={"note_"+i} className="StatusNote">
                      <div className="StatusNoteTitle">
                        {note.title}
                      </div>
                      <div className="StatusNoteBody">
                        {note.body}
                      </div>
                      {/* Button to delete the note */}
                      <Components.MaterialButton type="TextDark" size="Tiny" onClick={this.clearNote.bind(this, note.id)}>
                        Clear Note
                      </Components.MaterialButton>
                    </div>
                  ))}
                </div>
              </span>
            }
          </span>
        }
        { this.state.subview === "TODO" &&
          <span>
            <div className="TabSubtitle" style={{paddingTop:"30px"}}>TODO</div>
          </span>
        }
      </span>
    )
  }
}

export class SoundPlayer extends Component {

  constructor(props) {
    super(props)
    this.audio = React.createRef();
  }

  playSound() {
    if (this.audio.current !== null) {
      this.audio.current.load()
      this.audio.current.play()
    }
  }

  render() {
    return (
      <audio id={this.props.soundID} className="soundPlayerHidden" ref={this.audio} src={this.props.sound}>
      </audio>
    )
  }
}

export class BraintreeCardForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      hostedFieldsInstance: false,
      loading: true,
      error: false,
      defaultCard: true
    }
    this.setupClient = this.setupClient.bind(this)
    this.minimumNameLength = this.minimumNameLength.bind(this)
    this.defaultChanged = this.defaultChanged.bind(this);
    this.setupClient()
  }

  /*
  The minimum length for a name
  */
  minimumNameLength() {
    return 3
  }

  /*
  Sets up the braintree client and loads the credit card form.
  */
  setupClient() {
    braintree.hostedFields.create({
      client: this.props.client,
      styles: {
        'input': 'braintree-input-class',
        'input.invalid': 'braintree-input-invalid-class',
        'input.valid': 'braintree-input-valid-class',
        '::-webkit-input-placeholder': 'braintree-placeholder',
        ':-moz-placeholder': 'braintree-placeholder',
        '::-moz-placeholder': 'braintree-placeholder',
        ':-ms-input-placeholder': 'braintree-placeholder',
      },
      fields: {
        number: {
          selector: '#braintree-card-number',
          placeholder: '4111 1111 1111 1111',
          maskInput: 'true'
        },
        cvv: {
          selector: '#braintree-cvv',
          placeholder: '123',
          type: 'password'
        },
        expirationDate: {
          selector: '#braintree-expiration-date',
          placeholder: 'MM/YYYY'
        },
        postalCode: {
          selector: '#braintree-postal-code',
          placeholder: '90210'
        }
      }
    }).then(hostedFieldsInstance => {
      this.setState({
        hostedFieldsInstance: hostedFieldsInstance
      })
      $("#braintreeSubmitButton").removeAttr('disabled');
      //setup the focus callbacks
      this.setState({
        loading: false
      })
    }).catch(err => {
      console.log(err)
      this.props.showError(err.message)
    });
  }

  /*
  Called on form submit
  */
  onFormSubmit(event) {
    event.preventDefault();
    //1) Make sure the custom fields are valid
    let cardholderName = $("#braintree-name-input").val();
    if (cardholderName < this.minimumNameLength()) {
      console.log("cardholderName not valid")
      return false;
    }
    //2) Tokenize the hosted fields
    this.setState({
      loading: true,
      error: false
    })
    this.state.hostedFieldsInstance.tokenize({
      cardholderName: cardholderName
    }).then( payload => {
      console.log('Got a nonce: ' + payload.nonce);
      //3) Send the nonce to the server
      API.callDarwinAPI("POST", "billingCreditCard/" + this.props.organization.organizationID, {
        "token": payload.nonce,
        "default": (this.state.defaultCard ? 1 : 0),
        "editing": 0
      }, (result) => {
        if ("error" in result) {
          console.error(result.error)
          let e = result.error
          if (result.error.startsWith("Gateway Rejected:")) {
            let j = result.error.split(':')
            j.shift()
            e = "Invalid Credit Card Information: " + j.join(' ')
          }
          this.setState({
            loading: false,
            error: e
          })
          return
        }
        //4) Go back to the home page as we have saved this method.
        this.props.cancelAction();
      })
    }).catch( err => {
      console.error(err)
      this.setState({
        loading: false,
        error: err.message
      })
    });
    return false;
  }

  /*
  Toggles the classes on the provided field
  */
  toggleClasses(newClass, inputName) {
    document.getElementById(inputName).className = newClass
  }

  /*
  * The input has changed so determine if it is valid or not.
  */
  inputChanged(type, e) {
    //verify that name is set
    this.setState({
      name: e.target.value
    })
  }

  /*
  * The default switch has changed
  */
  defaultChanged(isOn) {
    this.setState({
      defaultCard: isOn
    })
  }

  render() {

    let nameClass = "braintree-additional-input braintree-input-class"
    if (this.state.name.length >= this.minimumNameLength()) {
      //names must be at least minimumNameLength characters on the card.
      nameClass = "braintree-additional-input braintree-input-valid-class"
    } else if (this.state.name.length < this.minimumNameLength()) {
      nameClass = "braintree-additional-input braintree-input-invalid-class"
    }

    let loadingStyle = {}
    if (this.state.loading) {
      loadingStyle.display = "none"
    }
    return (
      <div className="BraintreeCardForm">
        { this.state.loading &&
          <Components.LoadingIndicator style={{padding:"160px 0px"}}/>
        }
        <form action="/" id="braintreeForm" method="post" onSubmit={this.onFormSubmit.bind(this)}>
          <div style={loadingStyle} className="braintree-elements">

            <div className="braintree-element" style={{width:"100%"}}>
              <label htmlFor="braintree-name">Name On Card</label>
              <div id="braintree-name" className="braintree-input">
                <input id="braintree-name-input" type="text" placeholder="Charles R. Darwin" value={this.state.name} maxLength="200" className={nameClass} onChange={this.inputChanged.bind(this, "name")} ref={input => input && input.focus()}
                onFocus={(event) => {this.toggleClasses("braintree-input braintree-hosted-fields-focused", "braintree-name")}}
                onBlur={(event) => {this.toggleClasses("braintree-input", "braintree-name")}} />
              </div>
            </div>

            <div className="braintree-element" style={{width:"100%"}}>
              <label htmlFor="braintree-card-number">Card Number</label>
              <div id="braintree-card-number" className="braintree-input"></div>
            </div>

            <div className="braintree-element" style={{width:"180px"}}>
              <label htmlFor="braintree-expiration-date">Expiration Date</label>
              <div id="braintree-expiration-date" className="braintree-input"></div>
            </div>

            <div className="braintree-element" style={{width:"140px"}}>
              <label htmlFor="braintree-postal-code">Postal Code</label>
              <div id="braintree-postal-code" className="braintree-input"></div>
            </div>

            <div className="braintree-element" style={{width:"80px"}}>
              <label htmlFor="braintree-cvv">CVV</label>
              <div id="braintree-cvv" className="braintree-input"></div>
            </div>
          </div>
          <div style={loadingStyle} className="defaultSwitchDiv">
            <IOSSwitch handleChange={this.defaultChanged} checked={true} />
            <div className="details">
            Default Card. This card will be used for automated billing.
            </div>
          </div>
          <div style={loadingStyle} className="buttonsDiv">
            { this.props.cancel === true &&
              <MaterialButton onClick={this.props.cancelAction}>Cancel</MaterialButton>
            }
            <MaterialButton id="braintreeSubmitButton" type="submit" color="Blue" disabled>{this.props.submitText}</MaterialButton>
          </div>
          { this.state.error !== false && this.state.loading === false &&
            <div className="braintree-error-message">
              {this.state.error}
            </div>
          }
        </form>
      </div>
    )
  }
}

const SlideUpDiv = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      ease: easing.easeOut,
      duration: 500,
    },
    delay: ({iter}) => iter * 100
  },
  original: {
    y: 50,
    opacity: 0
  }
})

const SlideUpDivTogether = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      ease: easing.easeOut,
      duration: 500,
    },
    delay: ({iter}) => iter * 0
  },
  original: {
    y: 50,
    opacity: 0
  }
})

const DropDown = posed.div({
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: -20,
    opacity: 0
  }
})

export class Header extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dropDownState: "hidden"
    }
    this.toggleDropDown = this.toggleDropDown.bind(this)
    this.showMyAccount = this.showMyAccount.bind(this)
    this.toggleDarkMode = this.toggleDarkMode.bind(this)
    this.logout = this.logout.bind(this)
  }

  toggleDropDown() {
    if (this.state.dropDownState === "hidden") {
      this.setState({
        dropDownState: "visible"
      })
    } else {
      this.setState({
        dropDownState: "hidden"
      })
    }
  }

  showMyAccount() {
    this.props.switchTabs("Account")
  }

  logout() {
    this.props.logout()
  }

  toggleDarkMode() {
    this.props.changeDarkMode(!this.props.darkMode)
  }

  render() {

    let viewTabs = ["Account", "Billing", "Apps"]

    return (
      <div className="Header">
        <div className="leftIcon">
          <img src={this.props.darkMode ? ImageDarwinHeaderLight : ImageDarwinHeaderDark} alt="Darwin Logo" />
        </div>
        <div className="tabs">
          {viewTabs.map((tab, i) =>
            <div key={"tab" + i} className={"tab " + (tab === this.props.view ? "tabSelected" : "")} onClick={() => this.props.switchTabs(tab)}>
              {tab}
            </div>
          )}
        </div>
        <div className="profileDiv" onClick={this.toggleDropDown}>
          <img className="profileImage" src={this.props.userInfo.profilePicture != null ? this.props.userInfo.profilePicture : ImageDefaultProfile} alt="" />
          <div className="profileName">
            {this.props.userInfo.name !== undefined ? this.props.userInfo.name : ""}
          </div>
          <img className="profileDownArrow" src={ImageDownArrowGray} alt="" />
          <DropDown className={"DropDown DropDown" + this.state.dropDownState} pose={this.state.dropDownState}>
            <div className="element" onClick={this.showMyAccount}>
              <img src={this.props.darkMode ? ImageUserProfileLight : ImageUserProfileDark} alt="" />
              My Account
            </div>
            <div className="element" onClick={this.toggleDarkMode}>
              <img src={this.props.darkMode ? ImageLightningLight : ImageLightning} alt="" />
              { !this.props.darkMode &&
                <span>Dark Mode</span>
              }
              { this.props.darkMode &&
                <span>Light Mode</span>
              }
            </div>
            <div className="element" onClick={this.logout}>
              <img src={this.props.darkMode ? ImageLogoutLight : ImageLogout} alt="" />
              Logout
            </div>
          </DropDown>
        </div>
      </div>
    )
  }
}

export class Footer extends Component {

  render() {

    return (
      <div className="Footer">
        <div className="info">
          <div className="name">Darwin</div>
          <div className="motto">Evolutionary Programming</div>
          <div className="links">
            <a href="https://www.darwincloud.com/legal/privacy_policy.pdf">Privacy Policy</a> |&nbsp;
            <a href="https://www.darwincloud.com/legal/terms_of_use.pdf">Terms of Service</a>
          </div>
          <div className="copyright">© {(new Date()).getFullYear()} Darwin Inc. • All rights reserved</div>
        </div>
        <div className="logo">
          <img src={this.props.darkMode ? ImageDarwinWhiteLogo : ImageDarwinDarkLogo} alt="" />
        </div>
        <div className="disclaimer">
          { this.props.view === "Billing" &&
            <span>
              Disclaimer: Darwin does not store nor have access to your full credit/debit card numbers or card details.
              Payment processing is provided through the PCI compliant third party platform Braintree.
            </span>
          }
        </div>
      </div>
    )
  }
}

export class LoadingScreen extends Component {

  render() {
    return (
      <div className={"LoadingScreen " + (this.props.hide ? "LoadingScreenHidden" : "")}>
        { !this.props.hide &&
          <Components.LoadingIndicator />
        }
      </div>
    )
  }
}

export class Popup extends Component {

  render() {
    return (
      <div className="Popup">
        <div className="TabTitle">{this.props.title}</div>
        <div className="description">{this.props.message}</div>
        <MaterialButton onClick={this.props.dismiss}>OK</MaterialButton>
      </div>
    )
  }
}

// A popup that appears shortly and then disappears
// Call the show method on this element to show the action popup
export class ActionPopup extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showing: false
    }
  }

  show() {
    this.setState({
      showing: true
    })
    setTimeout(() => {
      this.setState({
        showing:false
      })
    }, 1000)
  }

  render() {
    let classes = "actionPopup "
    if (this.state.showing === true) {
      classes += "actionPopupVisible"
    }

    return (
      <div className={classes} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}

export class Confirmation extends Component {

  render() {
    return (
      <div className="Confirmation">
        <div className="ConfirmationBox">
          <div className="ConfirmationMessage">{this.props.message}</div>
          <div className="ConfirmationActions">
            <MaterialButton onClick={() => {this.props.dismiss(); this.props.action(false)}}>{this.props.deny}</MaterialButton>
            <MaterialButton color="Red" onClick={() => {this.props.dismiss(); this.props.action(true)}}>{this.props.accept}</MaterialButton>
          </div>
        </div>
      </div>
    )
  }
}

export class MaterialButton extends Component {

  render() {
    let classes = "MaterialButton btn"
    if (this.props.color !== undefined) {
      classes = "MaterialButton" + this.props.color + " MaterialButton btn"
    }
    let type = "button"
    if (this.props.type !== undefined) {
      type = "submit"
    }
    return (
      <button id={this.props.id} type={type} className={classes} onClick={this.props.onClick}>{this.props.children}</button>
    )
  }
}

export class PlusButton extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hovering: false
    }
  }

  hover(h) {
    this.setState({
      hovering: h
    })
  }

  render() {
    let image = ImagePlusGreen
    if (this.state.hovering) {
      image = ImagePlusGreenFilled
    }

    return (
      <div className="PlusButton" onClick={this.props.onClick} onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)}>
        <img src={image} alt="" />
      </div>
    )
  }
}

export class IOSSwitch extends Component {

  constructor(props) {
    super(props)
    this.state = {
      checked: this.props.checked
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(checked) {
    this.setState({
      checked: checked
    })
    if (this.props.handleChange !== undefined) {
      this.props.handleChange(checked)
    }
  }

  render() {

    let onColor = "#2CD5B2"
    if (this.props.color === "blue") {
      onColor = "#1E88E5"
      if (this.props.darkMode) {
        onColor = "#c6756f"
      }
    } else {
      onColor = "#2CD5B2"
      if (this.props.darkMode) {
        onColor = "#64d174"
      }
    }

    let offColor = "#DADADA"
    if (this.props.darkMode) {
      offColor = "#3D4F75"
    }

    return (
      <Switch
        onChange={this.handleChange}
        checked={this.state.checked}
        onColor={onColor}
        offColor={offColor}
        offHandleColor="#FFF"
        onHandleColor="#FFF"
        className={"iOSSwitch " + (this.state.checked ? "" : "iOSSwitchUnchecked")}
        uncheckedIcon={false}
        checkedIcon={false}
        height={35}
        width={70}
        handleDiameter={31}
        disabled={(this.props.disabled !== undefined && this.props.disabled ? true : false)}
      />
    )
  }
}

export class RealTimeSocket extends Component {

  constructor(props) {
    super(props)

    this.socket = false
    this.channelCallbackFunction = this.channelCallbackFunction.bind(this)
  }

  componentDidMount() {
    //1) Setup the socket.
    //for testing, use localhost 3001. We would need to startup the RealTimeContainer to get that to work though.
    //var socket = io.connect("localhost:3001", {path: "/rts/socket.io"});
    var socket = io.connect("https://rts.darwincloud.com");
    let channel = this.props.channel
    socket.on('connect', function() {
      //now try to subscribe to the passed in channel.
      socket.emit('subscribe', channel, API.getAccessToken(), API.getCSRFToken())
      //socket.emit('chat message subscribe', true)
    })
    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });

    //2) Create the callbacks for when we receive data.
    /*socket.on('chat message', function(msg) {
      console.log('received chat message:', msg)
    })*/
    socket.on('subscribed', function(success, message) {
      if (!success) {
        console.log("socket subscription error: ", message)
        if (message === "Auth Error") {
          console.log("we need to try refreshing the tokens, then we can try connecting again.")
          API.refreshTokens(() => {
            //now that the tokens have refreshed, try to subscribe again.
            socket.emit('subscribe', channel, API.getAccessToken(), API.getCSRFToken())
          })
        }
      } else {
        console.log("socket subscription result: ", message)
      }
    })
    socket.on(channel, this.channelCallbackFunction)
    this.socket = socket
  }

  componentWillUnmount() {
    //Disconnect the socket
    if (this.socket !== false && this.socket !== undefined) {
      console.log("Disconnecting the socket")
      this.socket.close()
    }
  }

  channelCallbackFunction(data) {
    this.props.callback(data)
  }

  render() {
    return (
      <span>
      </span>
    )
  }
}

// A paginator for use by a table to show multiple pages of results
// Pass in the props currentPage, totalPages, and action()
export class Pagination extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    //determine the order and number of the blocks to display
    let blocks = []
    blocks.push(this.props.currentPage)
    if (this.props.currentPage !== 0) {
      //add in the page before
      blocks.unshift(this.props.currentPage - 1)
    }
    if (this.props.currentPage !== this.props.totalPages - 1) {
      //add in the next page
      blocks.push(this.props.currentPage + 1)
    }
    if (blocks[blocks.length - 1] !== this.props.totalPages - 1) {
      //add in the ... and the last page
      blocks.push(-1)
      blocks.push(this.props.totalPages - 1)
    }
    if (blocks[0] !== 0) {
      //add in the ... and the first page
      blocks.unshift(-2)
      blocks.unshift(0)
    }


    return (
      <div className="paginationContainer" style={this.props.style}>
        { this.props.totalPages === 1 &&
          <div className="page pageSelected">1</div>
        }
        { this.props.totalPages > 1 &&
          <span>
            { this.props.currentPage !== 0 &&
              <div className="arrow" onClick={() => {this.props.action(this.props.currentPage - 1)}}>Back</div>
            }
            { blocks.map((cp) => (
                <span key={"cp" + (cp + 1)}>
                  { cp < 0 &&
                    <div className="pageDots">...</div>
                  }
                  { cp >= 0 && cp === this.props.currentPage &&
                    <div className="page pageSelected">{cp + 1}</div>
                  }
                  { cp >= 0 && cp !== this.props.currentPage &&
                    <div className="page" onClick={() => {this.props.action(cp)}}>{cp + 1}</div>
                  }
                </span>
              ))
            }
            { this.props.currentPage !== (this.props.totalPages - 1) &&
              <div className="arrow" onClick={() => {this.props.action(this.props.currentPage + 1)}}>Next</div>
            }
          </span>
        }
      </div>
    )
  }
}

//Sets the background color for the Body of the App.
export class BackgroundSet extends Component {

  componentDidMount() {
    this.props.bindMethod(this.props.color, this.props.priority, true)
  }

  componentWillUnmount() {
    this.props.bindMethod(this.props.color, this.props.priority, false)
  }

  render() {
    return (
      <span className="BackgroundSetSpecialClass">
      </span>
    )
  }
}

//Adds the bodyWhite class to the body div which will
//turn the background to be a white color. When this
//component is removed the class will be removed.
export class BackgroundSetGlobal extends Component {

  componentDidMount() {
    document.body.style["background-color"] = this.props.color;
  }

  componentWillUnmount() {
    document.body.style["background-color"] = "";
  }

  render() {
    return (
      <span className="BackgroundSetSpecialClass">
      </span>
    )
  }
}
