import { version } from '../../package.json'

//Returns the components of the url.
//For instance, /abc/def/yoyo would return ['abc', 'def', 'yoyo']
//A blank url would return ['']
export function getWindowPathComponents() {
  let path = window.location.pathname
  let pathComponents = path.split("/")
  if (pathComponents[0] === "" && pathComponents.length > 1) {
    //remove the first element as it is worthless.
    pathComponents.splice(0, 1)
  }
  return pathComponents
}

//Changes the url in the browser for help with deep linking.
export function updateWindowPath(newPath) {
  window.history.replaceState({}, "", newPath)
}

//Returns if we are in development or production
export function isDevelopment() {
  if (process && process.env && process.env.REACT_APP_STAGE === 'dev') {
    return true
  }
  return false
}

//Returns a random string of the provided length of alphanumeric characters
export function randomString(len) {
  var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
}

export function formatDate(date) {
  let t = date.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
  //now format the date for output
  let logDateExpandedFormat = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  return d.toLocaleDateString("en-US", logDateExpandedFormat)
}

export function formatDateShort(date) {
  let t = date.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
  //now format the date for output
  let logDateExpandedFormat = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  };
  let dd = d.toLocaleDateString("en-US", logDateExpandedFormat)
  return dd
}

export function parsePhoneNumber(phoneNumber) {
  //parse this into a phone number with (), spaces, and dashes
  let index = 0
  if (phoneNumber.length > 10) {
    index = phoneNumber.length - 10
  }
  let part0 = phoneNumber.substring(0, index)
  let part1 = phoneNumber.substring(index, index + 3)
  let part2 = phoneNumber.substring(index + 3, index + 6)
  let part3 = phoneNumber.substring(index + 6)

  let value = ""
  if (part0.length > 0) {
    value += part0 + " "
  }
  value += "(" + part1
  if (part1.length === 3 && part2.length > 0) {
    value += ") "
  }
  value += part2
  if (part2.length === 3 && part3.length > 0) {
    value += " - "
  }
  value += part3

  return value
}

export function formatNumber(numb) {
  if (numb === undefined) {
    return ""
  }
  return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function formatNumberOnlyInts(numb) {
  if (numb % 1 !== 0) {
    return ''
  }
  return numb + ''
}

export function formatNumberDecimals(numb, decimals = 2) {
  if (numb === undefined) {
    return ""
  }
  return parseFloat(numb).toFixed(decimals)
}

export function getAppVersion() {
  return version
}

export function dateInPast(dateString) {
  if (dateString === null || dateString === undefined || dateString === false) {
    return false
  }
  let t = dateString.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))

  let today = new Date();
  if (today > d) {
    return true
  } else {
    return false
  }
}
