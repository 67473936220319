import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

//import the javascript
import $ from 'jquery';
window.jQuery = $;

//import the code
var App = require('./App')

ReactDOM.render(<App.App />, document.getElementById('root'));
registerServiceWorker();

if (navigator.platform.toLowerCase().indexOf('mac') > -1) {
  console.log("Mac Platform");
  require('./DarwinReactLibrary/optional/mac.min.css');
} else if (navigator.platform.toLowerCase().indexOf('win') > -1) {
  console.log("Windows platform");
  require('./DarwinReactLibrary/optional/windows.min.css');
} else {
  console.log("Unknown Platform")
}
