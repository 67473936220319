import React from 'react';

import Select from 'react-select';
import ReactBodymovin from 'react-bodymovin'

import Validation from '../DarwinReactLibrary/js/Validation.js';
import * as Helpers from './Helpers.js';
import API from './API.js';

import ImagePlusBlue from '../DarwinReactLibrary/images/PlusBlue.svg';

//import all of the animations
//import darkAnimationJsonFile from './DarwinReactLibrary/animations/HexAnimation/DarwinLoadingBlack.json'
import grayAnimationJsonFile from '../DarwinReactLibrary/animations/HexAnimation/DarwinLoadingGray.json'
//import lightAnimationJsonFile from './DarwinReactLibrary/animations/HexAnimation/DarwinLoadingWhite.json'

//import jsonlint from 'jsonlint'
import * as fs from 'fs'
import * as os from 'os'
import * as CodeMirror from 'codemirror'

//Code Mirror
require('codemirror/lib/codemirror.css');

//themes & styling
require('codemirror/theme/neo.css');
require('codemirror/theme/material.css');
require("codemirror/addon/edit/matchbrackets.js")
require("codemirror/addon/lint/lint.js")
require("codemirror/addon/selection/mark-selection.js")

//searching
require("codemirror/addon/search/match-highlighter.js")
require("codemirror/addon/search/matchesonscrollbar.js")
require("codemirror/addon/search/searchcursor.js")
require("codemirror/addon/search/search.js")
require("codemirror/addon/search/jump-to-line.js")
require("codemirror/addon/dialog/dialog.js")

//code folding
require("codemirror/addon/fold/foldcode.js")
require("codemirror/addon/fold/foldgutter.js")
require("codemirror/addon/fold/brace-fold.js")

//autocomplete
require("codemirror/addon/hint/show-hint.js")
require("codemirror/addon/hint/anyword-hint.js")

//code modes
require("codemirror/mode/htmlmixed/htmlmixed.js")
require("codemirror/mode/xml/xml.js")
require("codemirror/mode/css/css.js")
require("codemirror/mode/clike/clike.js")
require("codemirror/mode/php/php.js")
require("codemirror/mode/javascript/javascript.js")
require("codemirror/mode/markdown/markdown.js")
require("codemirror/mode/sql/sql.js")
require("codemirror/mode/swift/swift.js")
require("codemirror/mode/yaml/yaml.js")
require("codemirror/mode/jsx/jsx.js")
require("codemirror/mode/go/go.js")
require("codemirror/mode/python/python.js")
require("codemirror/mode/octave/octave.js")
require("codemirror/mode/r/r.js")
require("codemirror/mode/ruby/ruby.js")
require("codemirror/mode/rust/rust.js")

//Changes the image on hover to the dual image
//set properties image, hoverimage, ...
export class DualImage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hovering: false
    }
  }

  //true or false we are hovering
  hover(h) {
    this.setState({
      hovering: h
    })
  }

  render() {

    /*if (this.props.image === false) {
      <img onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)} {...this.props}/>
    }*/
    let src = this.props.image
    if (this.state.hovering && this.props.hoverimage) {
      src = this.props.hoverimage
    }
    return (
      <img className={"dualImage " + (this.props.onClick ? "dualImageClickable " : "") + (this.props.className ? this.props.className : "")}
      onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)} alt="hover over me"
      src={src} {...this.props}
      style={this.props.style || {}}
      onClick={this.props.onClick ? this.props.onClick : undefined}
      />
    );
  }
}

export class MaterialButton extends React.Component {

  render() {

    let bclass = ""
    switch (this.props.type) {
      case "White":
        bclass = "MaterialButtonWhite"
        break;
      case "White2":
        bclass = "MaterialButtonWhite2"
        break;
      case "Red":
        bclass = "MaterialButtonRed"
        break;
      case "Green":
        bclass = "MaterialButtonGreen"
        break;
      case "Purple":
        bclass = "MaterialButtonPurple"
        break;
      case "Blue2":
        bclass = "MaterialButtonBlue2"
        break;
      case "TextDark":
        bclass = "MaterialButtonTextDark"
        break;
      case "TextDarkShadow":
        bclass = "MaterialButtonTextDarkShadow"
        break;
      case "TextRed":
        bclass = "MaterialButtonTextRed"
        break;
      case "TextRedShadow":
        bclass = "MaterialButtonTextRedShadow"
        break;
      case "TextPurpleShadow":
        bclass = "MaterialButtonTextPurpleShadow"
        break;
      case "TextGreenShadow":
        bclass = "MaterialButtonTextGreenShadow"
        break;
      case "PayPal":
        bclass = "MaterialButtonPayPal"
        break;
      default:
        break;
    }

    let sclass = ""
    switch (this.props.size) {
      case "Large":
        sclass = "MaterialButtonLarge"
        break;
      case "Small":
        sclass = "MaterialButtonSmall"
        break;
      case "Tiny":
        sclass = "MaterialButtonTiny"
        break;
      default:
        break;
    }

    return (
      <button type="button" className={"btn MaterialButton " + bclass + " " + sclass + (this.props.shake ? " AnimationShake" : "")}
        style={this.props.style} onClick={this.props.onClick}>{this.props.children}</button>
    )
  }
}

export class LoadingIndicator extends React.Component {

  render() {

    const bodymovinOptions = {
      loop: true,
      autoplay: true,
      prerender: true,
      animationData: grayAnimationJsonFile
    }

    return (
      <div className="LoadingIndicator" style={this.props.style} onClick={this.props.onClick}>
        <ReactBodymovin options={bodymovinOptions} />
      </div>
    );
  }
}

export class Popup extends React.Component {

  render() {
    return (
      <div className={"Popover " + (this.props.main === "Success" ? "PopoverSuccess" : "")}>
        <div className="PopupContainer">
          <div className="PopupMain">{this.props.main}</div>
          <div className="PopupTitle">{this.props.title}</div>
          <div className="PopupDescription">{this.props.description}</div>
          <div className="ButtonDiv">
            <MaterialButton type={this.props.main === "Success" ? "Green" : "Red"} size="Large" onClick={this.props.dismiss}>Ok</MaterialButton>
          </div>
        </div>
      </div>
    )
  }
}

export class Confirmation extends React.Component {

  render() {
    return (
      <div className="Popover PopoverBlue">
        <div className="PopupContainer">
          <div className="PopupMain">{this.props.main}</div>
          <div className="PopupTitle">{this.props.title}</div>
          <div className="PopupDescription">{this.props.description}</div>
          <div className="ButtonDiv">
            <MaterialButton type="Blue2" size="Large" style={{marginRight:"20px", marginLeft:"20px", marginBottom:"30px"}} onClick={() => {this.props.action(true)}}>{this.props.accept}</MaterialButton>
            <MaterialButton type="White" size="Large" onClick={() => {this.props.action(false)}}>{this.props.deny}</MaterialButton>
          </div>
        </div>
      </div>
    )
  }
}

export class FullScreenForm extends React.Component {

  constructor(props) {
    super(props)

    let data = this.props.initialData || {}
    /*data.notificationRadius = {
      valid: true,
      value: "0.0568182"
    }*/
    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      error: undefined,
      shakeButton: false,
      dataToSend: false,
      confirming: false,
      popup: false,
    }

    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)

    this.sendData = this.sendData.bind(this)
    this.callAPI = this.callAPI.bind(this)
    this.confirmationCallback = this.confirmationCallback.bind(this)
    this.dismissPopup = this.dismissPopup.bind(this)
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure every data element is valid.
    let valid = true
    for (let key in this.state.data) {
      if (this.state.data[key].valid === false) {
        valid = false
      }
    }
    //2) Make sure we have all of the data.
    let requiredFields = []
    for (let i = 0; i < this.props.elements.length; i = i + 1) {
      let ele = this.props.elements[i]
      if (ele.required === true) {
        requiredFields.push(ele.name)
      }
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        console.log("invalid", element, this.state.data[element])
        valid = false
      }
    })
    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        data[key] = this.state.data[key].value
      }
      //Submit the form
      this.sendData(data)
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Goes to the create shop page.
  */
  sendData(data) {
    //Ask for confirmation first
    if (this.props.confirmation) {
      this.setState({
        dataToSend: data,
        confirming: true,
      })
      return
    }
    this.setState({
      loading: true,
      dataToSend: data,
    }, () => {
      this.callAPI()
    })
  }

  callAPI() {
    API.callDarwinAPI(this.props.method, this.props.route, this.state.dataToSend, (result) => {
      if (this.props.log === true) {
        console.log(this.props.method + " " + this.props.route, result)
      }
      if ("error" in result) {
        this.setState({
          popup: {
            main: "Error",
            title: "There was an issue",
            description: result.error
          },
          loading: false
        })
        return
      }
      this.props.finished()
    })
  }

  confirmationCallback(result) {
    if (result) {
      this.setState({
        loading: true,
        confirming: false,
      }, () => {
        this.callAPI()
      })
    } else {
      this.setState({
        loading: false,
        dataToSend: false,
        confirming: false,
      })
    }
  }

  dismissPopup() {
    this.setState({
      popup: false
    })
  }

  render() {
    return (
      <div className="FullScreenForm">
        { this.state.confirming &&
          <Confirmation {...this.props.confirmation} action={this.confirmationCallback.bind(this)} />
        }
        { this.state.popup &&
          <Popup {...this.state.popup} dismiss={this.dismissPopup.bind(this)} />
        }
        { this.state.loading &&
          <LoadingIndicator />
        }
        { !this.state.loading && !this.state.popup && !this.state.confirming &&
          <div className="FullScreenFormElementsContainer">
            { this.props.elements.map((element, i) => (
              <span key={"ele_" + i}>
                { element.type === "header" &&
                  <div className="FullScreenHeader">
                    <div className="FullScreenHeaderTitle">{element.title}</div>
                    <div className="FullScreenHeaderDescription">{element.description}</div>
                  </div>
                }
                { element.type === "button" &&
                  <div className="ButtonDiv">
                    <MaterialButton type={element.buttonType === "submit" ? "Blue2" : "White"} size="Large" onClick={
                      element.buttonType === "submit" ? this.submitForm : this.props.finished
                    } shake={this.state.shakeButton}>{element.text}</MaterialButton>
                  </div>
                }
                { !["header", "button"].includes(element.type) &&
                  <div className="InputDiv">
                    <InputBottomLine type={element.type} name={element.name} placeholder={element.placeholder || ""} validation={element.validation} required={element.required} maximum={element.maximum || 0}
                      title={element.title || ""} description={element.description || ""}
                      value={this.state.data[element.name] ? this.state.data[element.name].value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                }
              </span>
            ))}
          </div>
        }
      </div>
    )
  }
}

export class InputBottomLine extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      valid: undefined,
      validResponse: undefined,
      failMessage: undefined
    }

    this.onKeyPress = this.onKeyPress.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onShopHoursChange = this.onShopHoursChange.bind(this)
    this.onFileSelectorChange = this.onFileSelectorChange.bind(this)
    this.updateValidation = this.updateValidation.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.forceCheck && this.props.forceCheck) || (prevProps.matchValue !== this.props.matchValue)) {
      this.updateValidation(this.props.value)
    }
  }

  onKeyPress(e) {
    if (e.keyCode === 13) {
      this.props.onEnter()
    }
  }

  onChange(e) {
    //validate this input and update the validation state.
    let val = e.target.value ? e.target.value : ""
    this.updateValidation(val)
  }

  onSelectChange(selection) {
    //console.log("selection: ", selection)
    let val = selection.value
    this.updateValidation(val)
  }

  onShopHoursChange(value, textValue) {
    //console.log("shopHoursValue: ", value)
    //console.log("shopHoursTextValue: ", textValue)
    //1) Validate the shopHours.
    if (this.updateValidation(value)) {
      //2) Update the shopHoursTextValue.
      this.props.onChange(this.props.name + "Text", textValue, true)
    }
  }

  onFileSelectorChange(url) {
    //console.log("file selection changes: ", url)
    this.updateValidation(url)
  }

  updateValidation(val) {
    let value = val
    let type = this.props.validation ? this.props.validation : "text"
    let req = (this.props.required === "true" || this.props.required === true) ? true : false
    let matchValue = this.props.matchValue ? this.props.matchValue : ""
    let min = this.props.minimum ? this.props.minimum : -1
    let max = this.props.maximum ? this.props.maximum : -1
    if (type === "phoneNumber" || type === "phoneNumber10") {
      value = value.replace(/[^0-9]/g, '')
      //console.log("value", value)
    } else if (type === "positiveNumber") {
      value = value.replace(/[^0-9]/g, '')
    }

    let result = {}
    if (matchValue) {
      result = Validation.validateMatch(value, type, req, matchValue, min, max);
    } else {
      result = Validation.validate(value, type, req, min, max);
    }

    this.setState({
      valid: result.valid,
      validResponse: result.validResponse,
      failMessage: result.failMessage
    })
    /*if (prevent) {
      e.preventDefault()
      return
    }*/
    this.props.onChange(this.props.name, value, result.valid)
    return result.valid
  }

  changeFilter() {
    let value = this.props.value
    for (let i = 0; i < this.props.options.length; i = i + 1) {
      if (this.props.options[i].value === value) {
        if (i + 1 < this.props.options.length) {
          value = this.props.options[i + 1].value
        } else {
          value = this.props.options[0].value
        }
        break
      }
    }
    this.props.onChange(this.props.name, value, true)
  }

  render() {
    let selectVal = null
    let selectIndex = 0
    let options = this.props.options
    if (this.props.type === "selection" || this.props.type === "filter") {
      if (options.length === 0) {
        options = [{
          value: "",
          label: "Loading ..."
        }]
        selectVal = options[0]
      }
      if (this.props.value) {
        for (let i = 0; i < this.props.options.length; i = i + 1) {
          if (this.props.value === this.props.options[i].value) {
            selectVal = this.props.options[i]
            selectIndex = i
            break
          }
        }
      }
      if (selectVal === null) {
        selectVal = options[0]
      }
    }

    let value = this.props.value
    if (value !== null && value.length > 0) {
      if (this.props.validation === "phoneNumber" || this.props.validation === "phoneNumber10") {
        //parse this into a phone number with (), spaces, and dashes
        value = Helpers.parsePhoneNumber(value)
      }
      if (this.props.validation === "positiveNumber") {
        //parse this into a readable number
        value = Helpers.formatNumber(value)
      }
    }

    let inputStyle = {}
    if (this.props.center) {
      inputStyle.textAlign = "center"
    }

    if (this.props.type === "filter") {
      return (
        <div className={"inputFilter " + (selectIndex > 0 ? "inputFilterChanged" : "")}>
          <div className="inputFilterBox" onClick={this.changeFilter}>
            { selectVal.label }
          </div>
        </div>
      )
    } else {
      return (
        <div className={"inputBottomLineLightContainer " + (this.state.valid === false ? "inputBottomLineLightContainerInvalid" : (this.state.valid === true ? "inputBottomLineLightContainerValid" : ""))}>
          { this.props.title &&
            <div className="inputBottomLineTitle">
              { this.props.title }
            </div>
          }
          { this.props.description &&
            <div className="inputBottomLineDescription">
              { this.props.description }
            </div>
          }
          <div className="inputBottomLineInvalid">
            { this.state.failMessage }
          </div>
          { this.props.type === "selection" &&
            <Select
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={selectVal}
              onChange={this.onSelectChange}
              options={options}
              clearable={false}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          }
          { this.props.type === "shopHours" &&
            <ShopHours
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={value}
              onChange={this.onShopHoursChange}
            />
          }
          { this.props.type === "file" &&
            <FileSelectorDiv
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={value}
              onChange={this.onFileSelectorChange}
              APIName={this.props.APIName}
              APIUploadName={this.props.APIUploadName}
              showPopup={this.props.showPopup}
              maxSize={this.props.maxSize}
            />
          }
          { (this.props.type === "text" || this.props.type === "password") &&
            <input tabIndex={this.props.tabIndex ? this.props.tabIndex : ""} type={this.props.type ? this.props.type : "text"}
              name={this.props.name} className="inputBottomLineLight" placeholder={this.props.placeholder} value={value}
              style={inputStyle}
              onKeyDown={this.onKeyPress} onChange={this.onChange} />
          }
          <div style={{clear: "both"}}></div>
          <div className="inputBottomLineBlockLight"></div>
        </div>
      )
    }
  }
}

export class ShopHours extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.parseTimeslots = this.parseTimeslots.bind(this)
    this.padNumber = this.padNumber.bind(this)
    this.onChange = this.onChange.bind(this)
    this.dayToAbbreviation = this.dayToAbbreviation.bind(this)
  }

  //Parses the timeslots out of the days and returns it as an array of days
  parseTimeslots(value) {
    let days = []
    let values = []
    if (value.length > 0) {
      values = value.split(",")
    }
    for (let i = 0; i < 7; i = i + 1) {
      let startTime = i * 24
      let stopTime = (i + 1) * 24

      let foundRange = "0" //0 is closed.
      //find any ranges that cover this timerange
      for (let r = 0; r < values.length; r = r + 1) {
        let times = values[r].split("-")
        let rangeStartTime = parseFloat(times[0].replace(":", "."))
        let rangeStartHour = parseInt(times[0].split(":")[0], 10)
        let rangeStartMinute = parseInt(times[0].split(":")[1], 10)

        let rangeStopTime = parseFloat(times[1].replace(":", "."))
        let rangeStopHour = parseInt(times[1].split(":")[0], 10)
        let rangeStopMinute = parseInt(times[1].split(":")[1], 10)

        let inRange = false
        if (startTime >= rangeStartTime && startTime < rangeStopTime) {
          //console.log("start time of day " + i + " is in range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        } else if (stopTime <= rangeStopTime && stopTime > rangeStartTime) {
          //console.log("stop time of day " + i + " is in range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        } else if (startTime < rangeStartTime && stopTime > rangeStopTime) {
          //console.log("day " + i + " contains range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        }

        if (inRange) {
          //we found the range for this day, now calculate the start and end time on a 0-24 hour schedule
          let stHour = 0
          let stMinute = 0
          let endHour = 24
          let endMinute = 0

          if (rangeStartTime > startTime) {
            stHour = rangeStartHour - (i * 24)
            stMinute = rangeStartMinute
          }
          if (rangeStopTime < stopTime) {
            endHour = rangeStopHour - (i * 24)
            endMinute = rangeStopMinute
          }

          if (stHour === 0 && stMinute === 0 && endHour === 24 && endMinute === 0) {
            //Open All Day
            foundRange = "1"
          } else {
            foundRange = this.padNumber(stHour, 2) + ":" + this.padNumber(stMinute, 2) + "-" + this.padNumber(endHour, 2) + ":" + this.padNumber(endMinute, 2)
          }
          break;
        }
      }
      days.push(foundRange)
    }
    return days
  }

  /*
  Pads a number with leading zeros.
  */
  padNumber(number, digits = 2) {
    let pad = ""
    for (let i = 0; i < digits; i = i + 1) {
      pad += "0"
    }
    let dmax = Math.max(digits, ("" + number).length)
    return (pad + number).slice(-dmax)
  }

  onChange(day, time1, time2) {
    //1) First parse into timeslots.
    let timeslots = this.parseTimeslots(this.props.value)
    //2) Update the day that was edited.
    let newValue = time1 + "-" + time2
    if (time1 === "0" || time1 === "1") {
      newValue = time1
    }
    timeslots[day] = newValue
    //3) Combine the timeslots.
    let combined = ""
    let textGroups = {}
    for (let i = 0; i < timeslots.length; i = i + 1) {
      let addString = ""
      if (timeslots[i] === "0") {
        //nothing to do as this is a closed date.
        if ("0" in textGroups) {
          textGroups["0"].push(i)
        } else {
          textGroups["0"] = [i]
        }
        continue;
      } else if (timeslots[i] === "1") {
        let startHour = 24 * i
        let endHour = 24 * (i + 1)
        addString = this.padNumber(startHour, 2) + ":00-" + this.padNumber(endHour, 2) + ":00"

        if ("1" in textGroups) {
          textGroups["1"].push(i)
        } else {
          textGroups["1"] = [i]
        }
      } else {
        let times = timeslots[i].split("-")
        let rangeStartHour = parseInt(times[0].split(":")[0], 10)
        let rangeStartMinute = parseInt(times[0].split(":")[1], 10)
        let rangeStopHour = parseInt(times[1].split(":")[0], 10)
        let rangeStopMinute = parseInt(times[1].split(":")[1], 10)

        let textRangeStartHour = rangeStartHour % 12
        if (textRangeStartHour === 0) {
          textRangeStartHour = 12
        }
        let textRangeStopHour = rangeStopHour % 12
        if (textRangeStopHour === 0) {
          textRangeStopHour = 12
        }

        let textString = this.padNumber(textRangeStartHour, 1) + ":" + this.padNumber(rangeStartMinute, 2) + "-" + this.padNumber(textRangeStopHour, 1) + ":" + this.padNumber(rangeStopMinute, 2)

        rangeStartHour += 24 * i
        rangeStopHour += 24 * i

        addString = this.padNumber(rangeStartHour, 2) + ":" + this.padNumber(rangeStartMinute, 2) + "-" + this.padNumber(rangeStopHour, 2) + ":" + this.padNumber(rangeStopMinute, 2)

        if (textString in textGroups) {
          textGroups[textString].push(i)
        } else {
          textGroups[textString] = [i]
        }
      }
      if (combined.length > 0) {
        combined += ","
      }
      combined += addString
    }

    //4) Parse the textGroups into a textString
    let textValue = ""
    let available = [0, 1, 2, 3, 4, 5, 6]
    while (available.length > 0) {
      let search = available[0]
      for (let key in textGroups) {
        if (textGroups[key].includes(search)) {
          //found the search.
          let days = textGroups[key]
          let last = -1
          let groupStart = -1
          let groupEnd = -1
          for (let i = 0; i < days.length; i = i + 1) {
            if (groupStart === -1) {
              //new group
              groupStart = days[i]
              groupEnd = days[i]
              last = days[i]
            } else if (days[i] === last + 1) {
              //extend the range
              groupEnd = days[i]
              last = days[i]
            } else {
              //range is over add it to the textValue, then reset.
              if (textValue.length > 0) {
                textValue += ", "
              }
              textValue += this.dayToAbbreviation(groupStart)
              if (groupEnd !== groupStart) {
                textValue += "-" + this.dayToAbbreviation(groupEnd)
              }

              groupStart = days[i]
              groupEnd = days[i]
              last = days[i]
            }
            available = available.filter((value, index, arr) => {
              return value !== days[i]
            })
          }
          //add in the range
          if (textValue.length > 0) {
            textValue += ", "
          }
          textValue += this.dayToAbbreviation(groupStart)
          if (groupEnd !== groupStart) {
            textValue += "-" + this.dayToAbbreviation(groupEnd)
          }
          //add in the text
          if (key === "0") {
            textValue += " Closed"
          } else if (key === "1") {
            textValue += " All Day"
          } else {
            textValue += " " + key
          }
          break
        }
      }
    }
    //5) Push the changes up to the next level.
    this.props.onChange(combined, textValue)
  }

  /*
  The day of the week, 0-6 where 0 is Monday.
  Converts to abbreviations like M, T, W, Th, F, Sa, Su
  */
  dayToAbbreviation(day) {
    switch (day) {
      case 0:
        return "M"
      case 1:
        return "Tu"
      case 2:
        return "W"
      case 3:
        return "Th"
      case 4:
        return "F"
      case 5:
        return "Sa"
      case 6:
        return "Su"
      default:
        return "?"
    }
  }

  render() {
    let timeslots = this.parseTimeslots(this.props.value)
    let hours = [
      {title:"Monday", day:0, value:timeslots[0]},
      {title:"Tuesday", day:1, value:timeslots[1]},
      {title:"Wednesday", day:2, value:timeslots[2]},
      {title:"Thursday", day:3, value:timeslots[3]},
      {title:"Friday", day:4, value:timeslots[4]},
      {title:"Saturday", day:5, value:timeslots[5]},
      {title:"Sunday", day:6, value:timeslots[6]},
    ]
    return (
      <div className="ShopHours">
        {hours.map((hour, i) => (
          <ShopHour key={"ShopHour" + i}
            {...hour}
            onChange={this.onChange}
          />
        ))}
      </div>
    )
  }
}

export class ShopHour extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection, action) {
    //get the old props.
    let startTime = this.props.value.split("-")[0]
    let endTime = this.props.value.split("-")[1]

    if (selection.value === "0" || selection.value === "1") {
      //Closed or All Day selected so send this as the value
      startTime = selection.value
      endTime = selection.value
    } else {
      if (action.name === "startTime") {
        startTime = selection.value
      } else {
        endTime = selection.value
      }
    }
    if (startTime === undefined) {
      startTime = "01:00"
    }
    if (endTime === undefined) {
      endTime = "23:00"
    }
    this.props.onChange(this.props.day, startTime, endTime)
  }

  render() {
    let fullOptions = [
      { value:"0", label:"Closed" },
      { value:"1", label:"All Day" },

      { value:"00:00", label:"Midnight" },
      { value:"00:15", label:"12:15 am" },
      { value:"00:30", label:"12:30 am" },
      { value:"00:45", label:"12:45 am" },

      { value:"01:00", label:"01:00 am" },
      { value:"01:15", label:"01:15 am" },
      { value:"01:30", label:"01:30 am" },
      { value:"01:45", label:"01:45 am" },

      { value:"02:00", label:"02:00 am" },
      { value:"02:15", label:"02:15 am" },
      { value:"02:30", label:"02:30 am" },
      { value:"02:45", label:"02:45 am" },

      { value:"03:00", label:"03:00 am" },
      { value:"03:15", label:"03:15 am" },
      { value:"03:30", label:"03:30 am" },
      { value:"03:45", label:"03:45 am" },

      { value:"04:00", label:"04:00 am" },
      { value:"04:15", label:"04:15 am" },
      { value:"04:30", label:"04:30 am" },
      { value:"04:45", label:"04:45 am" },

      { value:"05:00", label:"05:00 am" },
      { value:"05:15", label:"05:15 am" },
      { value:"05:30", label:"05:30 am" },
      { value:"05:45", label:"05:45 am" },

      { value:"06:00", label:"06:00 am" },
      { value:"06:15", label:"06:15 am" },
      { value:"06:30", label:"06:30 am" },
      { value:"06:45", label:"06:45 am" },

      { value:"07:00", label:"07:00 am" },
      { value:"07:15", label:"07:15 am" },
      { value:"07:30", label:"07:30 am" },
      { value:"07:45", label:"07:45 am" },

      { value:"08:00", label:"08:00 am" },
      { value:"08:15", label:"08:15 am" },
      { value:"08:30", label:"08:30 am" },
      { value:"08:45", label:"08:45 am" },

      { value:"09:00", label:"09:00 am" },
      { value:"09:15", label:"09:15 am" },
      { value:"09:30", label:"09:30 am" },
      { value:"09:45", label:"09:45 am" },

      { value:"10:00", label:"10:00 am" },
      { value:"10:15", label:"10:15 am" },
      { value:"10:30", label:"10:30 am" },
      { value:"10:45", label:"10:45 am" },

      { value:"11:00", label:"11:00 am" },
      { value:"11:15", label:"11:15 am" },
      { value:"11:30", label:"11:30 am" },
      { value:"11:45", label:"11:45 am" },

      { value:"12:00", label:"Noon" },
      { value:"12:15", label:"12:15 pm" },
      { value:"12:30", label:"12:30 pm" },
      { value:"12:45", label:"12:45 pm" },

      { value:"13:00", label:"01:00 pm" },
      { value:"13:15", label:"01:15 pm" },
      { value:"13:30", label:"01:30 pm" },
      { value:"13:45", label:"01:45 pm" },

      { value:"14:00", label:"02:00 pm" },
      { value:"14:15", label:"02:15 pm" },
      { value:"14:30", label:"02:30 pm" },
      { value:"14:45", label:"02:45 pm" },

      { value:"15:00", label:"03:00 pm" },
      { value:"15:15", label:"03:15 pm" },
      { value:"15:30", label:"03:30 pm" },
      { value:"15:45", label:"03:45 pm" },

      { value:"16:00", label:"04:00 pm" },
      { value:"16:15", label:"04:15 pm" },
      { value:"16:30", label:"04:30 pm" },
      { value:"16:45", label:"04:45 pm" },

      { value:"17:00", label:"05:00 pm" },
      { value:"17:15", label:"05:15 pm" },
      { value:"17:30", label:"05:30 pm" },
      { value:"17:45", label:"05:45 pm" },

      { value:"18:00", label:"06:00 pm" },
      { value:"18:15", label:"06:15 pm" },
      { value:"18:30", label:"06:30 pm" },
      { value:"18:45", label:"06:45 pm" },

      { value:"19:00", label:"07:00 pm" },
      { value:"19:15", label:"07:15 pm" },
      { value:"19:30", label:"07:30 pm" },
      { value:"19:45", label:"07:45 pm" },

      { value:"20:00", label:"08:00 pm" },
      { value:"20:15", label:"08:15 pm" },
      { value:"20:30", label:"08:30 pm" },
      { value:"20:45", label:"08:45 pm" },

      { value:"21:00", label:"09:00 pm" },
      { value:"21:15", label:"09:15 pm" },
      { value:"21:30", label:"09:30 pm" },
      { value:"21:45", label:"09:45 pm" },

      { value:"22:00", label:"10:00 pm" },
      { value:"22:15", label:"10:15 pm" },
      { value:"22:30", label:"10:30 pm" },
      { value:"22:45", label:"10:45 pm" },

      { value:"23:00", label:"11:00 pm" },
      { value:"23:15", label:"11:15 pm" },
      { value:"23:30", label:"11:30 pm" },
      { value:"23:45", label:"11:45 pm" },

      { value:"24:00", label:"Midnight" },
    ]

    //Find the value in the fullOptions given this.props.value
    let startValue = []
    let endValue = []
    let startOptions = fullOptions
    let endOptions = fullOptions
    let endDisabled = false
    if (this.props.value === "0") {
      startValue = fullOptions[0]
      endValue = fullOptions[0]
      endDisabled = true
    } else if (this.props.value === "1") {
      startValue = fullOptions[1]
      endValue = fullOptions[1]
      endDisabled = true
    } else {
      //we have a time to parse
      let startTime = this.props.value.split("-")[0]
      let endTime = this.props.value.split("-")[1]
      //now find the time
      for (let v = 0; v < fullOptions.length; v = v + 1) {
        if (fullOptions[v].value === startTime) {
          startValue = fullOptions[v]
        }
        if (fullOptions[v].value === endTime) {
          endValue = fullOptions[v]
        }
      }
    }

    return (
      <div className="ShopHour">
        {/* Show the day of the week */}
        <div className="ShopHourDay">{this.props.title}</div>
        {/* Show the first select option */}
        <Select
          name="startTime"
          options={startOptions}
          value={startValue}
          onChange={this.onChange}
          className="react-select-container ShopHourSelect"
          classNamePrefix="react-select"
        />
        {/* Show the dash */}
        <div className="ShopHourDash">–</div>
        {/* Show the second select option */}
        <Select
          name="stopTime"
          options={endOptions}
          value={endValue}
          onChange={this.onChange}
          isDisabled={endDisabled}
          className="react-select-container ShopHourSelect"
          classNamePrefix="react-select"
        />
      </div>
    )
  }
}

export class FileSelectorDiv extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      overlay: false,
      imgError: false,
    }

    this.goToSelectFile = this.goToSelectFile.bind(this)
    this.selectedFile = this.selectedFile.bind(this)
    this.imageLoadError = this.imageLoadError.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        imgError: false
      })
    }
  }

  goToSelectFile() {
    this.setState({
      overlay: true
    })
  }

  selectedFile(file) {
    this.setState({
      overlay: false
    })
    if (file.length === 0) {
      //we didn't select anything
      return
    }
    //set this as the newly selected file
    this.props.onChange(file)
  }

  imageLoadError() {
    console.log("image load error")
    this.setState({
      imgError: true
    })
  }

  render() {

    let value = this.props.value
    if (value === null || value.length === 0 || this.state.imgError) {
      value = ImagePlusBlue
    }

    let fullScreenFileSelectorProps = {
      finished: this.selectedFile,
      APIName: this.props.APIName,
      APIUploadName: this.props.APIUploadName,
      showPopup: this.props.showPopup,
      maxSize: this.props.maxSize,
    }

    return (
      <div className="FileSelectorDiv">
        <img className="FileSelectorDivImage" src={value} onError={this.imageLoadError} alt="Selected Logo" />
        <MaterialButton type="TextPurpleShadow" onClick={this.goToSelectFile}>Select File</MaterialButton>
        { this.state.overlay &&
          <FullScreenFileSelector {...fullScreenFileSelectorProps} />
        }
      </div>
    )
  }
}

export class FullScreenFileSelector extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      files: [],
      uploadingFile: false,
    }

    this.loadFiles = this.loadFiles.bind(this)
    this.cancel = this.cancel.bind(this)
    this.fileSelected = this.fileSelected.bind(this)

    this.filePicker = React.createRef();
  }

  componentDidMount() {
    //Load from the API
    this.loadFiles()
  }

  loadFiles() {
    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("GET", this.props.APIName, {}, (result) => {
        if ("error" in result) {
          this.props.showPopup("Error", "Couldn't load your files", result.error)
          return
        }
        this.setState({
          files: result.data,
          loading: false
        })
      })
    })
  }

  cancel() {
    this.props.finished("")
  }

  fileSelected() {
    if (this.filePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.filePicker.current.files[0]

    this.setState({
      uploadingFile: true
    }, () => {
      //1) Read the File
      let reader = new FileReader();
      reader.onload = (e) => {
        let img = document.createElement("img");
        img.onload = () => {
          //2) Wait for the file to load then resize the image
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          let MAX_WIDTH = this.props.maxSize;
          let MAX_HEIGHT = this.props.maxSize;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          //3) Convert the image to a blob
          canvas.toBlob((blob) => {

            //4) Call the API to retrieve signed post parameters
            API.callDarwinAPI("POST", this.props.APIUploadName, {}, (result) => {
              if ("error" in result) {
                this.props.showPopup("Error", "Couldn't load your files", result.error)
                return
              }
              //5) Upload the file.
              let formInputs = result.data.formInputs
              formInputs["Content-Type"] = "image/png"
              formInputs["Cache-Control"] = "max-age=2592000"
              API.uploadFileToS3(result.data.formAttributes, result.data.formInputs, result.data.filePath, blob, (result) => {
                console.log("file upload result", result)
                if (typeof result !== 'object' || "error" in result) {
                  //5) If error, show a popup to the user and stop loading.
                  this.setState({
                    uploadingFile: false
                  })
                  this.props.showPopup("Error", "Couldn't upload your file", result)
                  return
                } else {
                  //6) Cancel, go back, and reload the images.
                  this.setState({
                    uploadingFile: false
                  }, () => {
                    this.loadFiles()
                  })
                }
              })
            })
          }, "image/png")
        }
        img.src = e.target.result;
      }
      reader.readAsDataURL(file);
    })
  }

  render() {
    return (
      <div className="FullScreenFileSelector">
        { this.state.uploadingFile &&
          <div className="FullScreenFileSelectorFileLoading">
            <LoadingIndicator />
          </div>
        }
        { !this.state.uploadingFile &&
          <span>
            <div className="FullScreenFileSelectorTitle">File Selector</div>
            <div className="FullScreenFileSelectorDescription">Select an image or upload a new image using the plus button below.</div>
            <div className="ButtonDiv">
              <MaterialButton type="TextRedShadow" size="Large" onClick={this.cancel}>Cancel</MaterialButton>
            </div>
            <div className="FullScreenFileSelectorContainer">
              { this.state.loading &&
                <div className="FullScreenFileSelectorFileLoading">
                  <LoadingIndicator top="68px" />
                </div>
              }
              { !this.state.loading &&
                <div className="FullScreenFileSelectorFiles">
                  <div className="FullScreenFileSelectorFileUpload">
                    <input id="FullScreenFile" className="FullScreenFileUploaderInput" type="file" ref={this.filePicker} onChange={this.fileSelected} accept="image/png, image/jpeg" />
                    <label htmlFor="FullScreenFile">
                      <img src={ImagePlusBlue} alt="Upload" />
                    </label>
                  </div>
                  { !this.state.loading && this.state.files.map((file, i) => (
                    <div key={"file" + i} className="FullScreenFileSelectorFile" onClick={() => {this.props.finished(file.url)}}>
                      <img src={file.url} alt={"Icon " + i} />
                    </div>
                  ))}
                </div>
              }
            </div>
          </span>
        }
      </div>
    )
  }
}

/*
 * Code Editor, uses CodeMirror internally (https://codemirror.net), props listed below
 */
export class CodeEditor extends React.Component {

  constructor(props) {
    super(props)
    this.defaultFontSize = 16
    this.state = {
      fontSize: this.defaultFontSize
    }
    this.updateCount = 1;
    this.docID = "none";
    this.tabSize = 2;
    this.foldAll = true //flips between true and false

    this.setupCodeEditor = this.setupCodeEditor.bind(this)
  }

  /*
   * Lints the PHP code and returns any errors
   */
  getPHPAnnotations(text, callback, cm) {
    var phplint = require('phplint').lint

    //write the text to a temporary file
    const tempPath = __dirname + '/tempPHPOutputFile.php'
    if (this.props.codeType === "Function") {
      fs.writeFileSync(tempPath, "<?php\nfunction testFunc() {" + text + "\n}\n?>", (err) => {
        if (err) {
          throw err
        }
      });
    } else if (this.props.codeType === "Class") {
      fs.writeFileSync(tempPath, "<?php\nclass testClass {" + text + "\n}\n?>", (err) => {
        if (err) {
          throw err
        }
      });
    } else if (this.props.codeType === "Internal") {
      fs.writeFileSync(tempPath, "<?php\n" + text + "\n?>", (err) => {
        if (err) {
          throw err
        }
      });
    }

    phplint([tempPath], function (err, stdout, stderr) {
      var found = [];
      if (err) {
        let message = err.message
        let lineNumber = 2;
        let lineIndex = message.indexOf("on line");
        if (lineIndex !== -1) {
          lineNumber = parseInt(message.substr(lineIndex + 8), 10);
        }
        //let typeStart = message.indexOf("error: ");
        let typeEnd = message.indexOf("error,");
        //let messageType = "Other";
        //if (typeStart !== -1 && typeEnd !== -1) {
        //  messageType = message.substr(typeStart + 8, typeEnd - typeStart - 3);
        //}
        let messageEnd = message.indexOf(" in ");
        let messageText = "Error";
        if (typeEnd !== -1 && messageEnd !== -1) {
          messageText = message.substr(typeEnd + 7, messageEnd - typeEnd - 7)
        }
        //PHP Parse error:  syntax error, unexpected 'd' (T_STRING) in /Users/Blane/Electron/Darwin/resources/codemirror/addon/lint/tempPHPOutputFile.php on line 2
        found.push({
          from: CodeMirror.Pos(lineNumber - 2, 0),
          to: CodeMirror.Pos(lineNumber - 1, 0),
          severity: "error",
          message: messageText
        });
      }
      if (stdout) {
        console.log("stdout:",stdout)
      }
      if (stderr) {
        console.log("stderr",stderr)
      }
      callback(found);
    })
  }

  /*
   * Lints the JSON code and returns any errors
   */
  /*getJSONAnnotations(text, callback, cm) {
    var found = [];
    jsonlint.parser.parseError = function(str, hash) {
      var loc = hash.loc;
      found.push({from: CodeMirror.Pos(loc.first_line - 1, loc.first_column),
        to: CodeMirror.Pos(loc.last_line - 1, loc.last_column),
        severity: "error", message: str});
    };
    try {
      jsonlint.parse(text);
    } catch(e) {
    }
    console.log("json parse:", found)
    callback(found);
  }*/

  codeUpdated(editor, change) {
    //reindent on paste (adapted from https://github.com/ahuth/brackets-paste-and-indent/blob/master/main.js)
    if (change.origin === "paste") {
      var lineFrom = change.from.line;
      var lineTo = change.from.line + change.text.length;

      function reindentLines(editor, lineFrom, lineTo) {
        editor.operation(function() {
          editor.eachLine(lineFrom, lineTo, function(lineHandle) {
            editor.indentLine(lineHandle.lineNo(), "smart");
          });
        });
      }

      reindentLines(editor, lineFrom, lineTo);
    }

    if (this.updateCount === 0) {
      this.props.codeUpdated(editor)
    } else {
      this.updateCount -= 1
    }
  }

  /*
   * Returns the CodeMirror editor for any components that need access to it.
   */
  getEditor() {
    return this.codeMirror
  }

  componentDidMount() {
    //setup the codeEditor
    this.setupCodeEditor()
  }

  setupCodeEditor() {
    var textEditor = this.refs.editor
    if (textEditor != null) {
      let options = {
        lineWrapping: true,
        lineNumbers: true,
        firstLineNumber: this.props.firstLineNumber || 1,
        matchbrackets: true,
        gutters: ["CodeMirror-lint-markers", "CodeMirror-foldgutter"],
        foldGutter: true,
        fixedGutter: true,
        indentUnit: this.tabSize,
        indentWithTabs: true,
        tabSize: this.tabSize,
      }
      //set the theme
      let theme = "material"
      if (this.props.theme !== undefined) {
        theme = this.props.theme
      }
      options.theme = theme
      //highlighting text options
      if (this.props.highlightSelections !== undefined && this.props.highlightSelections === true) {
        options.highlightSelectionMatches = true
      }
      //Key Mappings
      let extraKeys = {}
      //Get the key command for Mac or control for Windows
      let osKey = "Cmd"
      if (os.platform() === "win32") {
        osKey = "Ctrl"
      } else if (os.platform() === "darwin") {
        osKey = "Cmd"
      }
      extraKeys[osKey + '-F'] = "findPersistent"
      extraKeys[osKey + '-H'] = "autocomplete"
      //get the language
      let lang = ""
      if (this.props.language !== undefined) {
        lang = this.props.language
      }
      /*let showAnnotations = true
      if (this.props.annotations !== undefined) {
        showAnnotations = this.props.annotations
      }*/
      switch (lang) {
        case "PHP":
          options.mode = "text/x-php"
          //TODO: Make sure PHP is installed instead of checking the OS. Checking the OS is wrong.
          /*if (os.platform() !== "win32" && showAnnotations) {
            options.lint = {
              async: true,
              getAnnotations: this.getPHPAnnotations.bind(this)
            }
          }*/
          break;
        case "JSON":
          options.mode = "application/json"
          /*if (showAnnotations) {
            options.lint = {
              async: true,
              getAnnotations: this.getJSONAnnotations.bind(this)
            }
          }*/
          break;
        case "JAVASCRIPT":
          options.mode = "text/javascript"
          break;
        case "CSS":
          options.mode = "text/css"
          break;
        case "SCSS":
          options.mode = "text/x-scss"
          break;
        case "LESS":
          options.mode = "text/x-less"
          break;
        case "JAVA":
          options.mode = "text/x-java"
          break;

        case "MARKDOWN":
          options.mode = "text/x-markdown"
          break;
        case "SCALA":
          options.mode = "text/x-scala"
          break;
        case "SQL":
          options.mode = "text/x-sql"
          break;
        case "SWIFT":
          options.mode = "text/x-swift"
          break;
        case "YAML":
          options.mode = "text/x-yaml"
          break;
        case "HTML":
          options.mode = "text/html"
          break;
        case "XML":
          options.mode = "application/xml"
          break;
        case "JSX":
          options.mode = "text/jsx"
          break;
        case "KOTLIN":
          options.mode = "text/x-kotlin"
          break;
        case "GO":
          options.mode = "text/x-go"
          break;
        case "PYTHON":
          options.mode = "text/x-python"
          break;
        case "OCTAVE":
          options.mode = "text/x-octave"
          break;
        case "R":
          options.mode = "text/x-rsrc"
          break;
        case "RUBY":
          options.mode = "text/x-ruby"
          break;
        case "RUST":
          options.mode = "text/x-rustsrc"
          break;
        default:
          //Show as Plain Text
          options.mode = "text/plain"
          break;
      }

      if (this.props.readOnly !== undefined) {
        options.readOnly = this.props.readOnly
      }

      this.codeMirror = CodeMirror.fromTextArea(textEditor, options);
  		this.codeMirror.on('change', this.codeUpdated.bind(this));
      let ExcludedIntelliSenseTriggerKeys = {
        "8": "backspace",
        "9": "tab",
        "13": "enter",
        "16": "shift",
        "17": "ctrl",
        "18": "alt",
        "19": "pause",
        "20": "capslock",
        "27": "escape",
        "33": "pageup",
        "34": "pagedown",
        "35": "end",
        "36": "home",
        "37": "left",
        "38": "up",
        "39": "right",
        "40": "down",
        "45": "insert",
        "46": "delete",
        "91": "left window key",
        "92": "right window key",
        "93": "select",
        "107": "add",
        "109": "subtract",
        "110": "decimal point",
        "111": "divide",
        "112": "f1",
        "113": "f2",
        "114": "f3",
        "115": "f4",
        "116": "f5",
        "117": "f6",
        "118": "f7",
        "119": "f8",
        "120": "f9",
        "121": "f10",
        "122": "f11",
        "123": "f12",
        "144": "numlock",
        "145": "scrolllock",
        "186": "semicolon",
        "187": "equalsign",
        "188": "comma",
        "189": "dash",
        "190": "period",
        "191": "slash",
        "192": "graveaccent",
        "220": "backslash",
        "222": "quote"
      }

      this.codeMirror.on("keyup", function(editor, event) {
        var __Cursor = editor.getDoc().getCursor();
        var __Token = editor.getTokenAt(__Cursor);

        if (!editor.state.completionActive &&
          !ExcludedIntelliSenseTriggerKeys[(event.keyCode || event.which).toString()] &&
          (__Token.type === "tag" || __Token.string === " " || __Token.string === "<" || __Token.string === "/")) {
            CodeMirror.commands.autocomplete(editor, null, { completeSingle: false });
        }
      });
  		//this.codeMirror.on('cursorActivity', this.cursorActivity);
  		//this.codeMirror.on('focus', this.focusChanged.bind(this, true));
  		//this.codeMirror.on('blur', this.focusChanged.bind(this, false));
  		//this.codeMirror.on('scroll', this.scrollChanged);
      this.codeMirror.setValue(this.props.code);


      //setup the save and deploy commands
      //code folding
      extraKeys[osKey + '-G'] = function(cm) {
        CodeMirror.commands.toggleFold(cm)
        //cm.foldCode(cm.getCursor());
      }//.bind(this)
      //code fold everything
      extraKeys['Shift-' + osKey + '-G'] = function(cm) {
        //code fold everything or unfold everything
        if (this.foldAll) {
          CodeMirror.commands.foldAll(cm)
          //need to focus on the cursor
          let t = cm.charCoords({line: cm.getCursor().line, ch: 0}, "local").top;
          let middleHeight = cm.getScrollerElement().offsetHeight / 2;
          cm.scrollTo(null, t - middleHeight - 5);
        } else {
          CodeMirror.commands.unfoldAll(cm)
          //need to focus on the cursor
          let t = cm.charCoords({line: cm.getCursor().line, ch: 0}, "local").top;
          let middleHeight = cm.getScrollerElement().offsetHeight / 2;
          cm.scrollTo(null, t - middleHeight - 5);
        }
        this.foldAll = !this.foldAll
      }.bind(this)
      //saving
      if (this.props.saveCommand !== undefined) {
        extraKeys[osKey + '-S'] = function(cm) {
          this.props.saveCommand(cm)
        }.bind(this)
      }
      //deploying
      if (this.props.deployCommand !== undefined) {
        extraKeys[osKey + '-D'] = function(cm) {
          this.props.deployCommand(cm)
        }.bind(this)
      }
      //ZOOMING IN AND OUT TO INCREASE AND DECREASE THE FONT SIZE
      if (this.props.disableZoom !== true) {
        extraKeys[osKey + '-='] = function(cm) {
          this.zoomCodeEditor(1)
        }.bind(this)
        extraKeys[osKey + '--'] = function(cm) {
          this.zoomCodeEditor(-1)
        }.bind(this)
        extraKeys[osKey + '-0'] = function(cm) {
          this.zoomCodeEditor(0)
        }.bind(this)
      }
      this.codeMirror.setOption("extraKeys", extraKeys)

      //highlight the middle line if necessary
      if (this.props.highlightLine !== false) {
        let hline = this.props.highlightLine - (this.props.firstLineNumber || 0)
        this.codeMirror.markText({line: hline, ch: 0}, {line: hline + 1, ch: 0}, {className: "CodeMirrorHighlighted"});
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.code !== undefined && nextProps.code !== this.codeMirror.getValue()) {
      this.updateCount += 1
      this.codeMirror.setValue(nextProps.code)
    }
    //clear the undo history if we are loading a new document
    if (nextProps.docID !== undefined && nextProps.docID !== this.docID) {
      //console.log("new docID:", nextProps.docID, ":", this.docID)
      this.docID = nextProps.docID
      this.codeMirror.getDoc().clearHistory()
    }
    if (nextProps.theme !== this.props.theme) {
      if (this.codeMirror) {
  			this.codeMirror.toTextArea();
  		}
      setTimeout(() => {
        this.setupCodeEditor()
      }, 100)
    }
  }

  componentWillUnmount () {
		// is there a lighter-weight way to remove the cm instance?
		if (this.codeMirror) {
			this.codeMirror.toTextArea();
		}
	}

  /**
   * Increases, Decreases, and Resets the font size of the code editor
   */
  zoomCodeEditor(zoomUp) {
    console.log("Should Zoom", zoomUp)
    if (zoomUp === 1) {
      //increase by 1px
      this.setState({
        fontSize: this.state.fontSize + 1
      }, () => {
        this.codeMirror.refresh()
      })
    } else if (zoomUp === -1) {
      //decrease by 1px
      this.setState({
        fontSize: this.state.fontSize - 1
      }, () => {
        this.codeMirror.refresh()
      })
    } else if (zoomUp === 0) {
      //reset to default
      this.setState({
        fontSize: this.defaultFontSize
      }, () => {
        this.codeMirror.refresh()
      })
    }
  }

  render() {

    return (
      <div style={{width:"100%", height:"100%", fontSize: this.state.fontSize+"px"}}>
        { this.props.downloadCode !== undefined && (this.props.downloadingCode === undefined || this.props.downloadingCode !== true) &&
          <button type="button" className="btn codeEditorDownloadButton" onClick={() => this.props.downloadCode(this.codeMirror)}><span>Download Code&nbsp;</span><img src="../resources/images/Primary/DownloadCodeWhite.svg" alt="Download Code" style={{width:"20px",height:"20px"}} /></button>
        }
        { this.props.downloadingCode !== undefined && this.props.downloadingCode === true &&
          <LoadingIndicator area="26" separation="0" style={{position:"absolute",zIndex:"1",right:"2px",bottom:"2px"}} type="light" />
        }
        { (this.props.topContent !== undefined && this.props.sideContent !== undefined) &&
          <span>
            <div id="codeEditorTopPanel" className="codeEditorSide" style={{whiteSpace:"nowrap"}}>
              {this.props.topContent}
            </div>
            <div id="codeWrapperDiv">
              <textarea id="codeEditor" ref="editor"></textarea>
            </div>
            <div className="codeEditorSide">
              {this.props.sideContent}
            </div>
          </span>
        }
        { (this.props.topContent !== undefined && this.props.sideContent === undefined) &&
          <span>
            <div id="codeEditorTopPanel" className="codeEditorSide" style={{whiteSpace:"nowrap"}}>
              {this.props.topContent}
            </div>
            <div id="codeWrapperDivHalf">
              <textarea id="codeEditor" ref="editor"></textarea>
            </div>
          </span>
        }
        { this.props.topContent === undefined &&
          <div id="codeWrapperDivFull">
            <textarea id="codeEditor" ref="editor"></textarea>
          </div>
        }
      </div>
    );
  }
}
